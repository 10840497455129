import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const suggestionAdapter = createEntityAdapter({});

const initialState = suggestionAdapter.getInitialState();

export const suggestionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query({
      query: () => ({
        url: "/suggestion",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedSuggestions = responseData.map((suggestion) => {
          suggestion.id = suggestion._id;
          return suggestion;
        });
        return suggestionAdapter.setAll(initialState, loadedSuggestions);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Suggestion", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Suggestion", id })),
          ];
        } else return [{ type: "Suggestion", id: "LIST" }];
      },
    }),
    addNewSuggestion: builder.mutation({
      query: (newSuggestion) => ({
        url: "/suggestion",
        method: "POST",
        body: {
          ...newSuggestion,
        },
      }),
      invalidatesTags: [{ type: "Suggestion", id: "LIST" }],
    }),
    deleteSuggestion: builder.mutation({
      query: (id) => ({
        url: `/suggestion`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Suggestion", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetSuggestionsQuery,
  useAddNewSuggestionMutation,
  useDeleteSuggestionMutation,
} = suggestionApiSlice;

// returns the query result object
export const selectSuggestionsResult =
  suggestionApiSlice.endpoints.getSuggestions.select();

// creates memoized selector
const selectSuggestionsData = createSelector(
  selectSuggestionsResult,
  (SuggestionsResult) => SuggestionsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllSuggestions,
  selectById: selectSuggestionById,
  selectIds: selectSuggestionIds,
  // Pass in a selector that returns the Suggestions slice of state
} = suggestionAdapter.getSelectors(
  (state) => selectSuggestionsData(state) ?? initialState
);
