import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Profile from "./components/pages/Profile";
import Accounts from "./components/pages/Accounts";
import Account from "./components/pages/Account";
import NewAccount from "./components/pages/NewAccount";
import EditAccount from "./components/pages/EditAccount";
import Performance from "./components/pages/Performance";
import Cashiers from "./components/pages/Cashiers";
import Terminals from "./components/pages/Terminals";
import EditTerminal from "./components/pages/EditTerminal";
import NewCashier from "./components/pages/NewCashier";
import EditCashier from "./components/pages/EditCashier";
import NewTerminal from "./components/pages/NewTerminal";
import Messages from "./components/pages/Messages";
import Accountants from "./components/pages/Accountants";
import NewAccountant from "./components/pages/NewAccountant";
import Announcement from "./components/pages/Announcement";
import Shortage from "./components/pages/Shortage";
import NewAnnouncement from "./components/pages/NewAnnouncement";
import Calendar from "./components/pages/Calendar";
import Suggestion from "./components/pages/Suggestion";
import NewTerminalRequest from "./components/pages/NewTerminalRequest";
import TerminalRequests from "./components/pages/TerminalRequests";
import Request from "./components/pages/Request";
import AdminAccount from "./components/pages/AdminAccount";
import LegacyAdminAccount from "./components/pages/LegacyAdminAccount";
import LegacyAccount from "./components/pages/LegacyAccount";
import Page404 from "./components/pages/Page404";
import Unauthorized from "./components/pages/Unauthorized";
import AuthenticateUser from "./store/auth/AuthenticateUser";
import AuthenticateAdmin from "./store/auth/AuthenticateAdmin";
// import Prefetch from "./store/auth/Prefetch.js";
import PersistLogin from "./store/auth/PersistLogin.js";

const App = () => {
  return (
    // <Routes>
    //   <Route path='/' element={<Home/>}/>
    //   <Route path='/Login' element={<Login/>}/>
    //   <Route path='/*' element={<Page404/>}/>
    // </Routes>
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/terminal" element={<NewTerminalRequest />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/*" element={<Page404 />} />

        {/* {Protected Routes} */}
        <Route element={<PersistLogin />}>
          <Route element={<AuthenticateUser />}>
            {/* <Route element={<Prefetch />}> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/account" element={<Accounts />} />
            <Route path="/account/legacy" element={<LegacyAccount />} />
            <Route path="/account/:id" element={<Account />} />
            <Route path="/account/edit/:id" element={<EditAccount />} />
            <Route path="/Performance" element={<Performance />} />
            <Route path="/cashier" element={<Cashiers />} />
            <Route path="/cashier/new" element={<NewCashier />} />
            <Route path="/account/create" element={<NewAccount />} />
            <Route path="/announcement" element={<Announcement />} />
            <Route path="/shortage" element={<Shortage />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/suggestion" element={<Suggestion />} />
            <Route element={<AuthenticateAdmin />}>
              <Route path="/admin/account" element={<AdminAccount />} />
              <Route
                path="/admin/account/legacy"
                element={<LegacyAdminAccount />}
              />
              <Route path="/admin/message" element={<Messages />} />
              <Route path="/admin/requests" element={<TerminalRequests />} />
              <Route path="/admin/request/:id" element={<Request />} />
              <Route path="/admin/terminal" element={<Terminals />} />
              <Route path="/admin/terminal/new" element={<NewTerminal />} />
              <Route path="/admin/terminal/:id" element={<EditTerminal />} />
              <Route path="/admin/accountant" element={<Accountants />} />
              <Route path="/admin/accountant/new" element={<NewAccountant />} />
              <Route path="/admin/accountant/new" element={<NewAccountant />} />
              <Route path="/announcement/new" element={<NewAnnouncement />} />
              <Route path="/cashier/edit/:id" element={<EditCashier />} />
            </Route>
            {/* </Route> */}
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
export default App;
