import AccountantInfo from "../AccountantInfo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import WrapLayout from "../WrapLayout";
const Accountants = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  const newButtonHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    navigate("/admin/accountant/new");
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "2rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Accountants</Typography>
          </Breadcrumbs>

          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={newButtonHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <Add />
              )
            }
          >
            New {matches ? "Accountant" : "Acc"}
          </Button>
        </Box>

        <Box sx={{ maxWidth: "80%", width: "80%" }}>
          <AccountantInfo />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Accountants;
