import { Add, Campaign, NavigateNext } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementList from "../AnnouncementList";
import WrapLayout from "../WrapLayout";

const Announcement = () => {
  const { role } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  const announceButtonHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    navigate("/announcement/new");
  };
  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "2rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Announcement</Typography>
          </Breadcrumbs>

          {role === "admin" && (
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={announceButtonHandler}
              endIcon={
                loading ? (
                  <CircularProgress
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                    size={"1rem"}
                  />
                ) : (
                  <Add />
                )
              }
            >
              New&nbsp; {matches ? "Announcement" : <Campaign />}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            maxWidth: { xs: "100%", sm: "90%" },
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AnnouncementList />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Announcement;
