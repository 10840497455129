import { useSelector } from "react-redux";
import { selectCurrentToken } from "../store/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);

  if (token) {
    const decoded = jwtDecode(token);
    const { email, role, firstName, lastName, phone, avatarUrl, _id } =
      decoded.UserInfo;
    const fullName = `${firstName} ${lastName}`;
    return { email, role, fullName, phone, avatarUrl, id: _id };
  }

  return { email: "", role: "" };
};
export default useAuth;
