import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const accountAdapter = createEntityAdapter({});

const initialState = accountAdapter.getInitialState();

export const accountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (id) => ({
        url: `/account/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((account) => {
          account.id = account._id;
          return account;
        });
        return accountAdapter.setAll(initialState, loadedAccounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Account", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Account", id })),
          ];
        } else return [{ type: "Account", id: "LIST" }];
      },
    }),
    getAccount: builder.query({
      query: (accId) => ({
        url: `/account/query/${accId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((account) => {
          account.id = account._id;
          return account;
        });
        return accountAdapter.setAll(initialState, loadedAccounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Account", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Account", id })),
          ];
        } else return [{ type: "Account", id: "LIST" }];
      },
    }),
    addNewAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/account",
        method: "POST",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: [{ type: "Account", id: "LIST" }],
    }),
    updateAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/account",
        method: "PATCH",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
    updateAccountShort: builder.mutation({
      query: (initialAccount) => ({
        url: "/account",
        method: "PUT",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
    getLegacyAccount: builder.query({
      query: ({ date, id }) => ({
        url: `/account/legacy/${id}?date=${date}`,
        method: "Get",
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((account) => {
          account.id = account._id;
          return account;
        });
        return accountAdapter.setAll(initialState, loadedAccounts);
      },
    }),
    deleteAccount: builder.mutation({
      query: ({ id }) => ({
        url: `/account`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAccountsQuery,
  useGetAccountQuery,
  useAddNewAccountMutation,
  useUpdateAccountMutation,
  useGetLegacyAccountQuery,
  useUpdateAccountShortMutation,
  useDeleteAccountMutation,
} = accountApiSlice;

// returns the query result object
export const selectAccountsResult =
  accountApiSlice.endpoints.getAccounts.select();

// creates memoized selector
const selectAccountsData = createSelector(
  selectAccountsResult,
  (AccountsResult) => AccountsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds,
  // Pass in a selector that returns the Accounts slice of state
} = accountAdapter.getSelectors(
  (state) => selectAccountsData(state) ?? initialState
);
