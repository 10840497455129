import TerminalCard from "./TerminalCard";
import { useGetTerminalsQuery } from "../store/terminal/terminalApiSlice";
import { Box, CircularProgress, Typography } from "@mui/material";

const TerminalInfo = () => {
  const {
    data: terminals,
    isLoading,
    isSuccess,
    isError,
  } = useGetTerminalsQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Terminals </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = terminals;
    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4"> No Terminal Yet </Typography>
          <Typography variant="p" component="p">
            Try adding new terminal
          </Typography>
        </Box>
      );
    }

    const reversedIds = ids.slice().reverse();
    return reversedIds.map((terminalId) => (
      <TerminalCard key={terminalId} terminal={entities[terminalId]} />
    ));
  }
};

export default TerminalInfo;
