import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useState } from "react";
import { East, NavigateNext } from "@mui/icons-material";
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import WrapLayout from "../WrapLayout";
import "../CalendarCard.scss";
import AdminAccountInfo from "../AdminAccountInfo";

const LegacyAdminAccount = () => {
  const [value, setValue] = useState(dayjs().toISOString());
  const [date, setDate] = useState(null);

  const selectBtnHandler = () => {
    setDate(value);
  };

  const changeBtnHandler = () => {
    setDate(null);
  };

  const calendar = (
    <Box
      sx={{
        p: "3rem 0",
        width: "30%",
        minWidth: "22.375rem",
        margin: "0 auto",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          orientation="portrait"
          openTo="day"
          value={value}
          onChange={(newValue) => {
            setValue(newValue.toISOString());
          }}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, _value, DayComponentProps) => {
            return (
              <Badge key={day.toString()} overlap="circular">
                <PickersDay {...DayComponentProps} />
              </Badge>
            );
          }}
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
        />
      </LocalizationProvider>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          mt: "1.5rem",
        }}
      >
        <Box
          sx={{
            textAlign: "end",
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={selectBtnHandler}
            endIcon={<East />}
          >
            select
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const changeDateBtn = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        p: "1rem 0",
        m: "2rem 0",
      }}
    >
      <Button
        variant="contained"
        sx={{ width: "fit-content" }}
        onClick={changeBtnHandler}
        endIcon={<East />}
      >
        Change Date
      </Button>
    </Box>
  );

  const adminAccount = (
    <Box>
      <AdminAccountInfo date="today" legacy={value} />
    </Box>
  );

  return (
    <WrapLayout>
      <Box
        sx={{
          width: "100%",
          borderBottom: "1px solid",
          paddingBottom: "1rem",
          margin: "1.5rem 0 1rem",
          borderColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/dashboard">
            Home
          </Link>
          <Typography color="text.primary">Legacy Account</Typography>
        </Breadcrumbs>
      </Box>
      {date ? changeDateBtn : calendar}
      {date && adminAccount}
    </WrapLayout>
  );
};

export default LegacyAdminAccount;
