import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography, Link } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import SuggestionForm from "../SuggestionForm";
import SuggestionList from "../SuggestionList";
import WrapLayout from "../WrapLayout";

const Suggestion = () => {
  const { role } = useAuth();

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Suggestion</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ width: "90%" }}>
          {!role === "admin" && <SuggestionForm />}
          <Box mt="2rem">
            {role === "admin" && (
              <>
                <Typography
                  variant="h4"
                  component="h3"
                  align="center"
                  mb="1rem"
                >
                  Suggesstions
                </Typography>
                <Box>
                  <SuggestionList />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Suggestion;
