import { DoneRounded, EastRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAddNewMessageMutation } from "../store/message/messageApiSlice";
import PhoneFormat from "./PhoneFormat";

const MessageForm = () => {
  const [message, setMessage] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [msgDelivered, setMsgDelivered] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [msgLoading, setMsgLoading] = useState(false);

  const [addNewMessage, { isSuccess, isError, error }] =
    useAddNewMessageMutation();

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (phone) => {
    if (String(phone).length === 11) {
      return true;
    } else {
      return false;
    }
  };

  const canSubmit =
    !!message.name &&
    !!message.email &&
    validateEmail(message.email) &&
    !!message.phone &&
    validatePhone(message.phone) &&
    !!message.message;

  const emailErrMsg =
    (!message.email && "Email Cannot Be Empty") ||
    (!validateEmail(message.email) && "Pls enter a valid Email Address");

  const phoneErrMsg =
    (!message.phone && "Phone Number Cannot Be Empty") ||
    (!validatePhone(message.phone) && "Pls enter a valid Phone Number");

  const changeHandler = (e) => {
    const target = e.target;
    setMessage((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  useEffect(
    () => {
      if (isSuccess) {
        setMsgDelivered(true);
      }
    },
    [isSuccess],
    setMsgDelivered
  );

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!canSubmit) return;
    setMsgLoading(true);
    await addNewMessage({
      ...message,
    });
  };

  const form = (
    <>
      <Typography
        component="h4"
        variant="h4"
        sx={{ textAlign: "center", pb: "0.5rem" }}
        id="message"
      >
        Get In Touch
      </Typography>
      <Box component="form" onSubmit={submitHandler}>
        <TextField
          id="Name"
          label="Name"
          name="name"
          value={message.name}
          onChange={changeHandler}
          variant="filled"
          margin="dense"
          fullWidth
          error={submitted && !message.name}
          helperText={submitted && !message.name && "Name Cannot Be Empty"}
        />
        <TextField
          id="Email Address"
          label="Email Address"
          name="email"
          value={message.email}
          onChange={changeHandler}
          variant="filled"
          margin="dense"
          fullWidth
          error={submitted && !!emailErrMsg}
          helperText={submitted && emailErrMsg}
        />

        <TextField
          id="Phone Number"
          label="Phone Number"
          name="phone"
          value={message.phone}
          onChange={changeHandler}
          variant="filled"
          margin="dense"
          InputProps={{
            inputComponent: PhoneFormat,
          }}
          fullWidth
          error={submitted && !!phoneErrMsg}
          helperText={submitted && phoneErrMsg}
        />

        <TextField
          id="Message"
          label="Type Your Message"
          name="message"
          value={message.message}
          onChange={changeHandler}
          multiline
          rows={4}
          variant="filled"
          margin="dense"
          fullWidth
          error={submitted && !message.message}
          helperText={
            submitted && !message.message && "Message Cannot Be Empty"
          }
        />

        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ marginTop: "1rem" }}
          endIcon={
            msgLoading ? (
              <CircularProgress
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
                size={"1rem"}
              />
            ) : (
              <EastRounded />
            )
          }
        >
          Submit
        </Button>
      </Box>
    </>
  );

  const submittedCard = (
    <Box sx={{ textAlign: "center" }} color="success">
      <DoneRounded sx={{ fontSize: "3rem" }} color="success" />
      <Typography component="h5" variant="h5" id="message">
        Thank You For Reaching Out
      </Typography>
    </Box>
  );
  return <>{msgDelivered ? submittedCard : form}</>;
};

export default MessageForm;
