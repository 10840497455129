import Header from "./Header";
import Footer from "./Footer";
import { Box, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

const WrapperBox = styled(Box)({
  minHeight: "100vh",
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  width: "100%",
  maxWidth: "96rem",
  backgroundColor: "#F2F0F455",
});

const Layout = () => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <WrapperBox>
        <Header />
        <Box sx={{ p: "0 0 1rem", m: "0" }}>
          <Outlet />
        </Box>
        <Footer />
      </WrapperBox>
    </Box>
  );
};

export default Layout;
