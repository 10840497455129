import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

const NumberFormat = forwardRef(function NumberFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      prefix="₦  "
    />
  );
});

export default NumberFormat;
