import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetShortagesQuery } from "../store/shortage/shortageApiSlice";
import ShortageCard from "./ShortageCard";
import useAuth from "../hooks/useAuth";

const ShortageList = () => {
  const { id, role } = useAuth();

  const {
    data: shortages,
    isLoading,
    isError,
    isSuccess,
  } = useGetShortagesQuery();

  const loading = (
    <Box sx={{ textAlign: "center", m: "2rem 0" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        size={"3rem"}
      />
    </Box>
  );

  const error = (
    <Box>
      <Typography variant="h5"> Something Went Wrong </Typography>
      <Typography> Unable To Fetch Shortages </Typography>
    </Box>
  );

  const empty = (
    <Box>
      <Typography variant="h5"> No Shortage At The Moment </Typography>
      <Typography variant="p" component="p">
        Keep Up The Good Work.
      </Typography>
    </Box>
  );

  let isEmpty;
  let reversedIds;
  let reversedShort;
  let data;

  if (isSuccess) {
    const { ids, entities } = shortages;
    const acctShortIds = [];
    data = entities;

    ids.forEach((shtId) => {
      if (entities[shtId].accountant._id === id) {
        acctShortIds.push(shtId);
      }
    });

    isEmpty = !ids?.length;

    reversedIds = ids.slice().reverse();
    reversedShort = acctShortIds.slice().reverse();
  }

  return (
    <Box
      sx={{
        width: "100%",
        m: "1rem 0",
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mb="1rem">
            Shortages
          </Typography>
        </Box>
        {isLoading && loading}
        {isError && error}
        {isEmpty && !isLoading && empty}
        {isSuccess &&
          !isEmpty &&
          role === "admin" &&
          reversedIds.map((shortId) => (
            <ShortageCard key={shortId} shortage={data[shortId]} role={role} />
          ))}
        {isSuccess &&
          !isEmpty &&
          role !== "admin" &&
          reversedShort.map((shortId) => (
            <ShortageCard key={shortId} shortage={data[shortId]} role={role} />
          ))}
      </Box>
    </Box>
  );
};

export default ShortageList;
