import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const accAccountAdapter = createEntityAdapter({});

const initialState = accAccountAdapter.getInitialState();

export const accAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccAccounts: builder.query({
      query: (id) => ({
        url: `/accountant/account/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        return accAccountAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "AccAccount", id: "LIST" },
            ...result.ids.map((id) => ({ type: "AccAccount", id })),
          ];
        } else return [{ type: "AccAccount", id: "LIST" }];
      },
    }),
    addNewAccAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/accountant/account",
        method: "POST",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: [{ type: "AccAccount", id: "LIST" }],
    }),
    updateAccAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/accountant/account",
        method: "PATCH",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AccAccount", id: arg.id },
      ],
    }),
    updateAccAccountShort: builder.mutation({
      query: (initialAccount) => ({
        url: "/accountant/account",
        method: "PUT",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AccAccount", id: arg.id },
      ],
    }),
    deleteAccAccount: builder.mutation({
      query: ({ id }) => ({
        url: `/accountant/account`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AccAccount", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAccAccountsQuery,
  useAddNewAccAccountMutation,
  useUpdateAccAccountMutation,
  useUpdateAccAccountShortMutation,
  useDeleteAccAccountMutation,
} = accAccountApiSlice;

// returns the query result object
export const selectAccAccountsResult =
  accAccountApiSlice.endpoints.getAccAccounts.select();

// creates memoized selector
const selectAccAccountsData = createSelector(
  selectAccAccountsResult,
  (AccountsResult) => AccountsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds,
  // Pass in a selector that returns the Accounts slice of state
} = accAccountAdapter.getSelectors(
  (state) => selectAccAccountsData(state) ?? initialState
);
