import CashierForm from "../CashierForm";
import { Box, Typography, Breadcrumbs, Link } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const NewCashier = () => {
  const initialCashierData = {
    title: "",
    firstName: "",
    lastName: "",
    nickName: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    localGov: "",
    avatar: "",
    avatarName: "No profile photo",
  };

  const initialGuarantorData = {
    title: "",
    firstName: "",
    lastName: "",
    relationship: "",
    natureOfBusiness: "",
    street: "",
    city: "",
    state: "",
    localGov: "",
    avatar: "",
    avatarName: "No profile photo",
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/cashier">
              Cashiers
            </Link>
            <Typography color="text.primary">Create Cashier</Typography>
          </Breadcrumbs>
        </Box>
        <CashierForm
          initialCashierData={initialCashierData}
          initialGuarantorData={initialGuarantorData}
        />
      </Box>
    </WrapLayout>
  );
};

export default NewCashier;
