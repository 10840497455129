import { Box, Typography, Link, Button } from "@mui/material";
const Unauthorized = () => {
  return (
    <Box sx={{ m: "2rem auto", textAlign: "center" }}>
      <Typography variant="h1" component="h2">
        401
      </Typography>
      <Typography variant="h6" component="h2">
        Sorry, You Are Unauthorized
      </Typography>
      <Link href="/" underline="none">
        <Button variant="contained" sx={{ mt: "1rem" }}>
          Return Home
        </Button>
      </Link>
    </Box>
  );
};

export default Unauthorized;
