import { Box, Typography, Divider, Card, CardContent } from "@mui/material";

const thousand = new Intl.NumberFormat("en-US");

const AdminAccountCard = ({ account }) => {
  return (
    <Box sx={{ width: "80%", margin: "0 auto" }}>
      <Card sx={{ mb: "1.2rem", width: "100%" }}>
        <CardContent>
          <Divider sx={{ mb: "0.5rem" }}>
            <Typography variant="body2" color="primary">
              {account.accId}
            </Typography>
          </Divider>

          <Typography variant="h6">COUNT: {account.no}</Typography>
          <Typography variant="h6">
            CASHOUT: {thousand.format(account.cashOut)}
          </Typography>
          <Typography variant="h6">
            FUND: {thousand.format(account.fund)}
          </Typography>
          <Typography variant="h6" color="success.main">
            CASHIN: {thousand.format(account.cashIn)}
          </Typography>
          <Typography variant="h6" color="error">
            DEDUCT: {thousand.format(account.deduct)}
          </Typography>
          <Typography variant="h6" color="error">
            CASH SHORT: {thousand.format(account.short)}
          </Typography>
          <Typography variant="h6" color="error">
            ACC SHORT:
            {thousand.format(account?.shortage ? account.shortage.amount : 0)}
          </Typography>
          <Typography variant="h6">
            EXPENSES:{" "}
            {thousand.format(account?.expenses ? account.expenses.amount : 0)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminAccountCard;
