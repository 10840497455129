import AnnouncementForm from "../AnnouncementForm";
import { Box, Typography, Breadcrumbs, Link } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const NewAnnouncement = () => {
  const initialData = {
    head: "",
    body: "",
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/announcement">
              Announcements
            </Link>
            <Typography color="text.primary">New</Typography>
          </Breadcrumbs>
        </Box>
        <AnnouncementForm initialData={initialData} />
      </Box>
    </WrapLayout>
  );
};

export default NewAnnouncement;
