import moment from "moment";
import { useDeleteTerminalRequestMutation } from "../store/terminalRequest/terminalRequestApiSlice";
import { useNavigate } from "react-router-dom";
import { Delete, Padding } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const RequestCard = ({ request, id }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [deleteTerminalRequest, { error, isSuccess, isError }] =
    useDeleteTerminalRequestMutation(id);

  useEffect(() => {
    // !flash message delete
  }, [isSuccess]);

  const clickHandler = () => {
    navigate(`/admin/request/${id}`);
  };

  const deleteHandler = async () => {
    setLoading(true);
    await deleteTerminalRequest(id);
  };

  return (
    <Card sx={{ mb: { xs: "1.2rem", sm: "2rem" } }}>
      <CardActionArea onClick={clickHandler}>
        <CardHeader
          avatar={
            <Avatar
              alt={request.personal.firstName}
              src={request.identification.passport}
              sx={{ width: 56, height: 56 }}
            />
          }
          title={
            <Typography variant="h5">
              {`${request.personal.title.toUpperCase()} ${request.personal.firstName.toUpperCase()} ${request.personal.lastName.toUpperCase()}`}
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="h5">
            Id Type : {request.identification.idType}
          </Typography>

          <Typography variant="h5">
            Phone No : {`${request.personal.phone}`}
          </Typography>

          <Typography variant="h5">
            Created At : {moment(request.createdAt).format("LL")}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{ justifyContent: "flex-end", padding: "0.5rem 1rem 1rem" }}
      >
        <Button
          variant="outlined"
          onClick={deleteHandler}
          color="error"
          endIcon={
            loading ? (
              <CircularProgress color="error" size={"1rem"} />
            ) : (
              <Delete />
            )
          }
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default RequestCard;
