import MessageInfo from "../MessageInfo";
import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography, Link } from "@mui/material";
import WrapLayout from "../WrapLayout";
const Messages = () => {
  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "2rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Messages</Typography>
          </Breadcrumbs>
        </Box>

        <Box sx={{ maxWidth: "80%", width: "80%" }}>
          <MessageInfo />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Messages;
