import { Check } from "@mui/icons-material";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useDeleteShortageMutation } from "../store/shortage/shortageApiSlice";
import { useState } from "react";

const ShortageCard = ({ shortage, role }) => {
  console.log(
    "🚀 ~ file: ShortageCard.jsx:16 ~ ShortageCard ~ shortage:",
    shortage
  );
  const [loading, setLoading] = useState(false);
  const [deleteShortage] = useDeleteShortageMutation();
  const deleteHandler = async () => {
    setLoading(true);
    await deleteShortage(shortage.id);
  };
  return (
    <Box sx={{ maxWidth: "80" }}>
      <Card sx={{ mb: "1.2rem", width: "100%", pb: "0" }}>
        <CardContent sx={{ pb: "0" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" component="h5">
              {shortage.amount}
            </Typography>

            {shortage.paid && (
              <Button
                variant="contained"
                sx={{ cursor: "auto" }}
                color="success"
                size="small"
              >
                Paid
              </Button>
            )}
          </Box>
          <Divider></Divider>
          <Box>
            <Typography>
              Date: {moment(shortage.createdAt).format("L")}
              <div>
                {role === "admin" &&
                  `Update: ${moment(shortage.updatedAt).format("L")}`}
              </div>
            </Typography>
          </Box>
          <Box mt="0.5rem">
            <Typography
              variant="h5"
              component="span"
              sx={{ display: "inline" }}
            >
              {shortage.head} -
            </Typography>
            <Typography
              variant="body1"
              component="p"
              ml="0.5rem"
              sx={{ display: "inline" }}
            >
              {shortage.description}
            </Typography>
          </Box>
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {role === "admin" && !shortage.paid && (
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ m: "0 0 0.5rem 0.5rem" }}
              startIcon={
                loading ? (
                  <CircularProgress
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                    size={"1rem"}
                  />
                ) : (
                  <Check />
                )
              }
              onClick={deleteHandler}
            >
              paid
            </Button>
          )}
          <Typography
            variant="caption"
            component="small"
            sx={{ fontWeight: "bold", textAlign: "end" }}
            width={shortage.paid || role !== "admin" ? "100%" : "fit-content"}
          >
            {role !== "admin" && shortage.cashier?.nickName?.toUpperCase()}
            {role === "admin" &&
              `${shortage.accountant?.nickName?.toUpperCase()} - ${shortage.cashier?.nickName?.toUpperCase()}`}
          </Typography>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ShortageCard;
