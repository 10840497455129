import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../store/auth/authSlice";
import { useLoginMutation } from "../../store/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import {
  Box,
  Button,
  CircularProgress,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff, EastRounded } from "@mui/icons-material";

const LoginPage = () => {
  //   const router = useRouter();
  const emailRef = useRef();
  const errorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();
  const [persist, setPersist] = usePersist();

  // const [user, setUser] = useState('');
  // const [pwd, setPwd] = useState('');
  // const [errMsg, setErrMsg] = useState('');
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [loginData]);

  const inputChangeHandler = (inputProp) => (event) => {
    setLoginData((prevState) => ({
      ...prevState,
      [inputProp]: event.target.value,
    }));
  };

  const passwordVisibiityHandler = () => {
    setLoginData((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const inputPreventDefault = (event) => {
    event.preventDefault();
  };

  const LoginSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    setPersist(true);

    const { email, password } = loginData;
    try {
      const { accessToken } = await login({ email, password }).unwrap();
      dispatch(setCredentials({ accessToken }));
      setLoading((prevState) => ({
        email: "",
        password: "",
        showPassword: false,
      }));
      navigate("/dashboard");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (!error?.status) {
        setErrorMsg("No Server Response");
      } else if (error.status?.status === 400) {
        setErrorMsg("Email or Username is incorrect");
      } else if (error.status?.status === 401) {
        setErrorMsg("You are not Permitted");
      } else {
        setErrorMsg(error.data?.message);
      }
      // setErrorMsg(error.message);
      errorRef.current.focus();
    }
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
          <CircularProgress
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
            size={"3rem"}
          />
        </Box>
      )}
      {!isLoading && (
        <Box
          component="main"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "70rem" }}>
            <Box
              component="form"
              onSubmit={LoginSubmitHandler}
              sx={{
                maxWidth: { xs: "85%", sm: "60%" },
                width: "85%",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "2rem 0",
              }}
            >
              {
                <Box ref={errorRef} sx={{ width: "100%" }}>
                  {errorMsg && (
                    <Alert severity="error" sx={{ width: "100%" }}>
                      {errorMsg}
                    </Alert>
                  )}
                </Box>
              }

              <FormControl fullWidth variant="filled" margin="dense">
                <InputLabel htmlFor="email">Email</InputLabel>
                <FilledInput
                  id="email"
                  value={loginData.email}
                  onChange={inputChangeHandler("email")}
                  label="Email"
                  ref={emailRef}
                />
              </FormControl>

              <FormControl fullWidth variant="filled" margin="dense">
                <InputLabel htmlFor="password">Password</InputLabel>
                <FilledInput
                  id="password"
                  type={loginData.showPassword ? "text" : "password"}
                  value={loginData.password}
                  onChange={inputChangeHandler("password")}
                  label="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordVisibiityHandler}
                        onMouseDown={inputPreventDefault}
                        edge="end"
                      >
                        {loginData.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{ marginTop: "1rem" }}
                endIcon={
                  loading ? (
                    <CircularProgress
                      sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                      }}
                      size={"1rem"}
                    />
                  ) : (
                    <EastRounded />
                  )
                }
              >
                Login
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LoginPage;
