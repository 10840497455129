import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const terminalAdapter = createEntityAdapter({});

const initialState = terminalAdapter.getInitialState();

export const terminalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTerminals: builder.query({
      query: () => ({
        url: "/terminal",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedTerminal = responseData.map((terminal) => {
          terminal.id = terminal._id;
          return terminal;
        });
        return terminalAdapter.setAll(initialState, loadedTerminal);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Terminal", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Terminal", id })),
          ];
        } else return [{ type: "Terminal", id: "LIST" }];
      },
    }),
    addNewTerminal: builder.mutation({
      query: (initialTerminal) => ({
        url: "/terminal",
        method: "POST",
        body: {
          ...initialTerminal,
        },
      }),
      invalidatesTags: [{ type: "Terminal", id: "LIST" }],
    }),
    updateTerminal: builder.mutation({
      query: (initialTerminal) => ({
        url: "/terminal",
        method: "PATCH",
        body: {
          ...initialTerminal,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Terminal", id: arg.id },
      ],
    }),
    deleteTerminal: builder.mutation({
      query: ({ id }) => ({
        url: `/Terminal`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Terminal", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetTerminalsQuery,
  useAddNewTerminalMutation,
  useUpdateTerminalMutation,
  useDeleteTerminalMutation,
} = terminalApiSlice;

// returns the query result object
export const selectTerminalResult =
  terminalApiSlice.endpoints.getTerminals.select();

// creates memoized selector
const selectTerminalData = createSelector(
  selectTerminalResult,
  (TerminalResult) => TerminalResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTerminal,
  selectById: selectTerminalById,
  selectIds: selectTerminalIds,
  // Pass in a selector that returns the Terminal slice of state
} = terminalAdapter.getSelectors(
  (state) => selectTerminalData(state) ?? initialState
);
