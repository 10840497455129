import { useGetTerminalsQuery } from "../../store/terminal/terminalApiSlice";
import { useGetCashiersQuery } from "../../store/cashier/cashierApiSlice";
import AccountForm from "../AccountForm";
import uniqid from "uniqid";
import {
  Box,
  CircularProgress,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useGetAccountQuery } from "../../store/account/accountApiSlice";
// import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import WrapLayout from "../WrapLayout";

const EditAccount = () => {
  const { id } = useParams();
  // const { id: accId } = useAuth();
  const {
    data: terminalObj,
    isSuccess: termSuccess,
    isLoading: termLoading,
    isError: termError,
  } = useGetTerminalsQuery();

  const {
    data: cashierObj,
    isSuccess: cashSuccess,
    isLoading: cashLoading,
    isError: cashError,
  } = useGetCashiersQuery();

  // const { account } = useGetAccountsQuery(accId, {
  //   selectFromResult: ({ data }) => ({
  //     account: data?.entities[id],
  //   }),
  // });

  const {
    data: acct,
    isLoading: accountLoading,
    isSuccess: accountSuccess,
    isError: accountError,
  } = useGetAccountQuery(id);

  const getData = (dataObj) => {
    const Data = dataObj.ids.map((id) => dataObj?.entities[id]);
    return Data;
  };
  const terminals = termSuccess ? getData(terminalObj) : null;
  const cashiers = cashSuccess ? getData(cashierObj) : null;
  const account = accountSuccess ? acct?.entities[id] : null;

  const arrKeyAdder = (arr) => {
    const arrWithKey = [];
    if (!arr) {
      return [];
    }
    arr.forEach((value) => {
      arrWithKey.push({ value, key: uniqid.time() });
    });
    return arrWithKey;
  };

  const ratings = [
    { value: "mega", key: 5 },
    { value: "super", key: 4 },
    { value: "shooting", key: 3 },
    { value: "rising", key: 2 },
    { value: "starter", key: 1 },
  ];

  if (!account) {
    return (
      <Box sx={{ textAlign: "center", margin: "2rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  const initialData = {
    id: account.id,
    cashier: account.cashier.id,
    terminal: account.terminal.id,
    withdraw: "",
    withdrawArr: arrKeyAdder(account.withdrawArr),
    transPro: "",
    transProArr: arrKeyAdder(account.transProArr),
    transAcc: "",
    transAccArr: arrKeyAdder(account.transAccArr),
    posTrans: "",
    posTransArr: arrKeyAdder(account.posTransArr),
    deposit: "",
    depositArr: arrKeyAdder(account.depositArr),
    airtime: "",
    airtimeArr: arrKeyAdder(account.airtimeArr),
    bill: "",
    billArr: arrKeyAdder(account.billArr),
    fund: "",
    fundArr: arrKeyAdder(account.fundArr),
    deduct: "",
    deductArr: arrKeyAdder(account.deductArr),
    rating: account.rating,
    cashOut: account.cashOut,
  };

  const isError = termError || cashError || accountError;

  const errorContent = (
    <div>
      <Typography variant="h5"> Something Went Wrong </Typography>
      <Typography> Unable To Get Account Form </Typography>
    </div>
  );

  const isLoading = termLoading || cashLoading || accountLoading;

  const loadingContent = (
    <Box sx={{ textAlign: "center", m: "2rem 0" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        size={"3rem"}
      />
    </Box>
  );

  const isSuccess = terminals && cashiers && acct;

  const data = { terminals, cashiers, ratings, initialData };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/account">
              Accounts
            </Link>
            <Typography color="text.primary">Edit Account</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ width: "100%" }}>
          {isError && errorContent}
          {isLoading && loadingContent}
          {isSuccess && <AccountForm data={data} edit={true} />}
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default EditAccount;
