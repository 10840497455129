import { useGetMessagesQuery } from "../store/message/messageApiSlice";
import MessageCard from "./MessageCard";
import { Box, CircularProgress, Typography } from "@mui/material";

const MessageInfo = () => {
  const {
    data: messages,
    isLoading,
    isSuccess,
    isError,
  } = useGetMessagesQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Messages </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = messages;

    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4">No Message At The Moment.</Typography>
          <Typography variant="p" component="p">
            Check back later
          </Typography>
        </Box>
      );
    }

    return ids.map((messageId) => (
      <MessageCard
        key={messageId}
        message={entities[messageId]}
        id={messageId}
      />
    ));
  }
};

export default MessageInfo;
