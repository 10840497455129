import { useGetCashiersQuery } from "../store/cashier/cashierApiSlice";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { EastRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { useAddNewAccountantMutation } from "../store/accountant/accountantApiSlice";

const AccountantFormC = ({ source }) => {
  const navigate = useNavigate();
  const errorMsgRef = useRef();

  const [accountant, setAccountant] = useState({
    cashier: "",
    role: "",
    permission: "",
    email: "",
    password: "",
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showPassword, setShowPassword] = useState("");

  const {
    data: cashierObj,
    isSuccess: cashierSuccess,
    isLoading: cashierLoading,
    isError: cashierError,
  } = useGetCashiersQuery();

  const getData = (dataObj) => {
    const Data = dataObj.ids.map((id) => dataObj?.entities[id]);
    return Data;
  };

  const cashiers = cashierSuccess ? getData(cashierObj) : null;

  const [addNewAccountant, { isSuccess, isError, error }] =
    useAddNewAccountantMutation();

  useEffect(() => {
    if (isSuccess) {
      setAccountant({
        cashier: "",
        role: "",
        permission: "",
        email: "",
        password: "",
      });
      setSubmitClicked(false);
      setLoading(false);
      navigate("/admin/accountant");
    }
    if (isError) {
      setLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [isSuccess, navigate, isError, error]);

  const passwordVisibiityHandler = () => {
    setShowPassword(!showPassword);
  };

  const inputPreventDefault = (event) => {
    event.preventDefault();
  };

  const canSave =
    !!accountant.cashier &&
    !!accountant.role &&
    !!accountant.permission &&
    !!accountant.email &&
    !!accountant.password;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAccountant((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const emailErrMsg =
    (!accountant.email && "Email Cannot Be Empty") ||
    (!validateEmail(accountant.email) && "Pls enter a valid Email Address");

  const userSubmitHandler = async (event) => {
    setSubmitClicked(true);
    event.preventDefault();

    if (canSave) {
      setLoading(true);

      await addNewAccountant({
        ...accountant,
        source,
      });
    }
  };

  if (cashierError) {
    return (
      <Box>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Accountants </Typography>
      </Box>
    );
  }

  if (cashierLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (cashierSuccess) {
    return (
      <Box
        component="form"
        onSubmit={userSubmitHandler}
        sx={{
          maxWidth: "100%",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="h4" component="h2" sx={{ mb: "1rem" }}>
            Promote cashier
          </Typography>
        </Box>

        <Box ref={errorMsgRef} sx={{ width: "100%" }}>
          {errorMsg && (
            <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
              {errorMsg}
            </Alert>
          )}
        </Box>

        <FormControl
          fullWidth
          margin="dense"
          error={submitClicked && !accountant.cashier}
        >
          <InputLabel htmlFor="cashier">Cashier</InputLabel>
          <Select
            id="cashier"
            name="cashier"
            labelId="cashier"
            value={accountant.cashier}
            label="Cashier"
            onChange={inputChangeHandler}
          >
            {cashiers.map((cashier) => (
              <MenuItem value={cashier.id} key={cashier.id}>
                {cashier.nickName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {submitClicked && !accountant.cashier && "Cashier Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          margin="dense"
          fullWidth
          error={submitClicked && !accountant.role}
        >
          <InputLabel id="Role">Role</InputLabel>
          <Select
            labelId="Role"
            id="Role"
            name="role"
            value={accountant.role}
            onChange={inputChangeHandler}
          >
            <MenuItem value={"accountant"}>Accountant</MenuItem>
            <MenuItem value={"admin"}>Admin</MenuItem>
            <MenuItem value={"secretary"}>Secretary</MenuItem>
          </Select>
          <FormHelperText>
            {submitClicked && !accountant.role && "Role Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          margin="dense"
          fullWidth
          error={submitClicked && !accountant.permission}
        >
          <InputLabel id="Permission">Permission</InputLabel>
          <Select
            labelId="Permission"
            id="Permission"
            name="permission"
            value={accountant.permission}
            onChange={inputChangeHandler}
          >
            <MenuItem value={"true"}>Granted</MenuItem>
            <MenuItem value={"false"}>Restricted</MenuItem>
          </Select>
          <FormHelperText>
            {submitClicked &&
              !accountant.permission &&
              "Permission Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <TextField
          id="Email Address"
          label="Email Address"
          name="email"
          value={accountant.email}
          onChange={inputChangeHandler}
          margin="dense"
          fullWidth
          error={submitClicked && !!emailErrMsg}
          helperText={submitClicked && emailErrMsg}
        />

        <FormControl
          fullWidth
          margin="dense"
          error={submitClicked && !accountant.password}
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={accountant.password}
            onChange={inputChangeHandler}
            label="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={passwordVisibiityHandler}
                  onMouseDown={inputPreventDefault}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            {submitClicked &&
              !accountant.password &&
              "Password Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ marginTop: "1rem" }}
          endIcon={
            loading ? (
              <CircularProgress
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
                size={"1rem"}
              />
            ) : (
              <EastRounded />
            )
          }
        >
          Create
        </Button>
      </Box>
    );
  }
};

export default AccountantFormC;
