import {
  AddAPhoto,
  DoneRounded,
  EastRounded,
  WestRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import NaijaStates from "naija-state-local-government";
import DatePicker from "./DatePicker";
import PhoneFormat from "./PhoneFormat";
import NumericInputFormat from "./NumericInputFormat";
import { useAddNewTerminalRequestMutation } from "../store/terminalRequest/terminalRequestApiSlice";
import WrapLayout from "./WrapLayout";
const state = NaijaStates.states();

const TerminalRequestForm = () => {
  const navigate = useNavigate();
  const topText = useRef();
  const passportInput = useRef();
  const idPhotoInput = useRef();
  const signatureInput = useRef();

  const [addNewTerminalRequest, { isSuccess, isError, error }] =
    useAddNewTerminalRequestMutation();

  const [stepOne, setStepOne] = useState({
    title: { value: "", isFilled: false },
    first: { value: "", isFilled: false },
    last: { value: "", isFilled: false },
    dob: { value: "", isFilled: false },
    number: { value: "", isFilled: false },
    email: { value: "", isFilled: false },
  });

  const [stepTwo, setStepTwo] = useState({
    origin: {
      nationality: { value: "", isFilled: false },
      state: { value: "", isFilled: false },
      localGovt: { value: "", isFilled: false },
    },
    homeAdd: {
      street: { value: "", isFilled: false },
      city: { value: "", isFilled: false },
      localGovt: { value: "", isFilled: false },
      state: { value: "", isFilled: false },
    },
  });

  const [stepThree, setStepThree] = useState({
    idType: { value: "", isFilled: false },
    idNum: { value: "", isFilled: false },
    bvn: { value: "", isFilled: false },
    passport: { value: "", isFilled: false },
    idPhoto: { value: "", isFilled: false },
  });

  const [stepFour, setStepFour] = useState({
    name: { value: "", isFilled: false },
    signature: { value: "", isFilled: false },
    location: {
      street: { value: "", isFilled: false },
      city: { value: "", isFilled: false },
      localGovt: { value: "", isFilled: false },
      state: { value: "", isFilled: false },
    },
  });

  const [stepFive, setStepFive] = useState({
    name: { value: "", isFilled: false },
    number: { value: "", isFilled: false },
    relationship: { value: "", isFilled: false },
    dob: { value: "", isFilled: false },
    email: { value: "", isFilled: false },
    homeAdd: {
      street: { value: "", isFilled: false },
      city: { value: "", isFilled: false },
      localGovt: { value: "", isFilled: false },
      state: { value: "", isFilled: false },
    },
  });

  const [nextClicked, setNextClicked] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Personal Information",
    "Place Of Origin",
    "Means Of Identification",
    "Business Details",
    "Next of Kin",
  ];

  const stepOneIsFilled =
    stepOne.title.isFilled &&
    stepOne.first.isFilled &&
    stepOne.last.isFilled &&
    stepOne.dob.isFilled &&
    stepOne.number.isFilled &&
    stepOne.email.isFilled;

  const stepTwoIsFilled =
    stepTwo.origin.nationality.isFilled &&
    stepTwo.origin.state.isFilled &&
    stepTwo.origin.localGovt.isFilled &&
    stepTwo.homeAdd.street.isFilled &&
    stepTwo.homeAdd.city.isFilled &&
    stepTwo.homeAdd.localGovt.isFilled &&
    stepTwo.homeAdd.state.isFilled;

  const stepThreeIsFilled =
    stepThree.idType.value &&
    stepThree.idNum.value &&
    stepThree.bvn.value &&
    stepThree.passport.value &&
    stepThree.idPhoto.value;

  const stepFourIsFilled =
    stepFour.name.isFilled &&
    stepFour.signature.isFilled &&
    stepFour.location.street.isFilled &&
    stepFour.location.city.isFilled &&
    stepFour.location.localGovt.isFilled &&
    stepFour.location.state.isFilled;

  const stepFiveIsFilled =
    stepFive.name.value &&
    stepFive.number.value &&
    stepFive.relationship.value &&
    stepFive.dob.value &&
    stepFive.email.value &&
    stepFive.homeAdd.street.value &&
    stepFive.homeAdd.city.value &&
    stepFive.homeAdd.localGovt.value &&
    stepFive.homeAdd.state.value;

  const done = {
    1: stepOneIsFilled,
    2: stepTwoIsFilled,
    3: stepThreeIsFilled,
    4: stepFourIsFilled,
    5: stepFiveIsFilled,
  };

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePhone = (number) => {
    if (String(number).length === 11) {
      return true;
    } else {
      return false;
    }
  };

  //? ChangeHandlers

  const stepOneChangeHandler = (e) => {
    if (e.$d) {
      setStepOne((prev) => ({
        ...prev,
        dob: {
          value: dayjs(e).format("L"),
          isFilled: e.$d === "" ? false : true,
        },
      }));
      return;
    }
    const target = e.target;
    setStepOne((prev) => ({
      ...prev,
      [target.name]: {
        value: target.value,
        isFilled: target.value === "" ? false : true,
      },
    }));
  };

  const stepTwoChangeHandler = (cat) => (e) => {
    const target = e.target;
    setStepTwo((prev) => ({
      ...prev,
      [cat]: {
        ...prev[cat],
        [target.name]: {
          value: target.value,
          isFilled: target.value === "" ? false : true,
        },
      },
    }));
  };

  const stepThreeChangeHandler = (e) => {
    const target = e.target;
    if (target.name === "passport" || target.name === "idPhoto") {
      const image = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setStepThree((prev) => ({
          ...prev,
          [target.name]: {
            value: reader.result,
            isFilled: reader.result === "" ? false : true,
          },
        }));
      };
    } else {
      setStepThree((prev) => ({
        ...prev,
        [target.name]: {
          value: target.value,
          isFilled: target.value === "" ? false : true,
        },
      }));
    }
  };

  const stepFourChangeHandler =
    (cat = null) =>
    (e) => {
      const target = e.target;
      if (cat === "location") {
        setStepFour((prev) => ({
          ...prev,
          [cat]: {
            ...prev[cat],
            [target.name]: {
              value: target.value,
              isFilled: target.value === "" ? false : true,
            },
          },
        }));
      } else if (target.name === "signature") {
        const image = target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          setStepFour((prev) => ({
            ...prev,
            [target.name]: {
              value: reader.result,
              isFilled: reader.result === "" ? false : true,
            },
          }));
        };
      } else {
        setStepFour((prev) => ({
          ...prev,
          [target.name]: {
            value: target.value,
            isFilled: target.value === "" ? false : true,
          },
        }));
      }
    };

  const stepFiveChangeHandler =
    (cat = null) =>
    (e) => {
      const target = e.target;
      if (e.$d) {
        setStepFive((prev) => ({
          ...prev,
          dob: {
            value: dayjs(e).format("L"),
            isFilled: e.$d === "" ? false : true,
          },
        }));
        return;
      } else if (cat === "homeAdd") {
        setStepFive((prev) => ({
          ...prev,
          [cat]: {
            ...prev[cat],
            [target.name]: {
              value: target.value,
              isFilled: target.value === "" ? false : true,
            },
          },
        }));
      } else {
        setStepFive((prev) => ({
          ...prev,
          [target.name]: {
            value: target.value,
            isFilled: target.value === "" ? false : true,
          },
        }));
      }
    };

  const scrollToTop = () => {
    window.scrollTo({
      top: topText.current?.offsetTop,
      behavior: "smooth",
      nearest: "block",
    });
  };

  const formSubmitHandler = async () => {
    // formHandler
    await addNewTerminalRequest({
      personal: {
        ...stepOne,
      },
      placeOrigin: {
        ...stepTwo,
      },
      identification: {
        ...stepThree,
      },
      business: {
        ...stepFour,
      },
      kin: {
        ...stepFive,
      },
    });
  };

  const handleNext = (currStep) => (e) => {
    setNextClicked((prev) => ({
      ...prev,
      [currStep]: true,
    }));
    if (done[currStep]) {
      if (activeStep === 0 && !validateEmail(stepOne.email.value)) {
        scrollToTop();
        return;
      }
      if (activeStep === 0 && !validatePhone(stepOne.number.value)) {
        scrollToTop();
        return;
      }
      if (activeStep === 4 && !validateEmail(stepFive.email.value)) {
        scrollToTop();
        return;
      }
      if (activeStep === 4 && !validatePhone(stepFive.number.value)) {
        scrollToTop();
        return;
      }
      if (activeStep === steps.length - 1) {
        formSubmitHandler();
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      scrollToTop();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDone = () => {
    navigate("/");
  };

  const emailErrMsg =
    (!stepOne.email.isFilled && "Email Cannot Be Empty") ||
    (!validateEmail(stepOne.email.value) && "Pls enter a valid Email Address");

  const phoneErrMsg =
    (!stepOne.number.isFilled && "Phone Cannot Be Empty") ||
    (!validatePhone(stepOne.number.value) && "Pls enter a valid Phone Number");

  const emailErrMsgNxtKin =
    (!stepFive.email.isFilled && "Email Cannot Be Empty") ||
    (!validateEmail(stepFive.email.value) && "Pls enter a valid Email Address");

  const phoneErrMsgNxtKin =
    (!stepFive.number.isFilled && "Phone Cannot Be Empty") ||
    (!validatePhone(stepFive.number.value) && "Pls enter a valid Phone Number");

  const PhotoCard = ({
    title,
    refName,
    name,
    changeHandler,
    url,
    error,
    errMsg,
  }) => {
    return (
      <Box>
        <Typography m="0.5rem 0">{title}</Typography>
        <Box
          sx={{
            width: "100%",
            height: "10rem",
            backgroundImage: `url(${url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: (theme) => theme.palette.primary.inverse,
            borderRadius: "0.2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: "inherit",
            }}
            onClick={() => refName.current.click()}
          >
            <AddAPhoto sx={{ mr: "0.5rem" }} />
            <Typography color="inherit"> Choose a photo </Typography>
          </Button>

          <input
            ref={refName}
            id={name}
            name={name}
            type="file"
            accept="image/*"
            onChange={changeHandler}
            hidden
          />
        </Box>
        {error && (
          <Box>
            <Typography color="error" variant="h6">
              {errMsg}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const step1 = (
    <Box>
      <Typography>PERSONAL INFORMATION</Typography>

      <Box m="1.5rem 0">
        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepOne.title.isFilled}
        >
          <InputLabel id="Title">Title</InputLabel>
          <Select
            labelId="Title"
            id="Title"
            name="title"
            value={stepOne.title.value}
            onChange={stepOneChangeHandler}
          >
            <MenuItem value={"mr"}>Mr</MenuItem>
            <MenuItem value={"mrs"}>Mrs</MenuItem>
            <MenuItem value={"miss"}>Miss</MenuItem>
            <MenuItem value={"mister"}>Mister</MenuItem>
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepOne.title.isFilled &&
              "Title Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <TextField
          id="First Name"
          label="First Name"
          name="first"
          variant="filled"
          value={stepOne.first.value}
          onChange={stepOneChangeHandler}
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepOne.first.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepOne.first.isFilled &&
            "First Name Cannot Be Empty"
          }
        />
        <TextField
          id="Last Name (Surname)"
          label="Last Name (Surname)"
          name="last"
          value={stepOne.last.value}
          onChange={stepOneChangeHandler}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepOne.last.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepOne.last.isFilled &&
            "Last Name Cannot Be Empty"
          }
        />
        <DatePicker
          name="dob"
          label="Date of Birth"
          value={stepOne.dob.value}
          changeHandler={stepOneChangeHandler}
          error={nextClicked[activeStep + 1] && !stepOne.dob.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepOne.dob.isFilled &&
            "Pls Choose a Date"
          }
        />

        <TextField
          id="Phone Number"
          label="Phone Number"
          name="number"
          value={stepOne.number.value}
          onChange={stepOneChangeHandler}
          variant="filled"
          margin="dense"
          InputProps={{
            inputComponent: PhoneFormat,
          }}
          fullWidth
          error={nextClicked[activeStep + 1] && !!phoneErrMsg}
          helperText={nextClicked[activeStep + 1] && phoneErrMsg}
        />
        <TextField
          id="Email Address"
          label="Email Address"
          name="email"
          value={stepOne.email.value}
          onChange={stepOneChangeHandler}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !!emailErrMsg}
          helperText={nextClicked[activeStep + 1] && emailErrMsg}
        />
      </Box>
    </Box>
  );

  const step2 = (
    <Box>
      <Typography>PLACE OF ORIGIN</Typography>

      <Box m="1.5rem 0">
        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepTwo.origin.nationality.isFilled
          }
        >
          <InputLabel id="Nationality">Nationality</InputLabel>
          <Select
            labelId="Nationality"
            id="Nationality"
            name="nationality"
            value={stepTwo.origin.nationality.value}
            onChange={stepTwoChangeHandler("origin")}
          >
            <MenuItem value={"nigeria"}>Nigeria</MenuItem>
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepTwo.origin.nationality.isFilled &&
              "Nationality Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepTwo.origin.state.isFilled}
        >
          <InputLabel id="State">State</InputLabel>
          <Select
            labelId="State"
            id="State"
            name="state"
            value={stepTwo.origin.state.value}
            onChange={stepTwoChangeHandler("origin")}
          >
            {state.map((stat, i) => {
              return (
                <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                  {stat}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepTwo.origin.state.isFilled &&
              "State Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepTwo.origin.localGovt.isFilled
          }
        >
          <InputLabel id="Local Govt.">Local Govt.</InputLabel>
          <Select
            labelId="Local Govt."
            id="Local Govt."
            name="localGovt"
            value={stepTwo.origin.localGovt.value}
            onChange={stepTwoChangeHandler("origin")}
          >
            {stepTwo.origin.state.isFilled &&
              NaijaStates.lgas(stepTwo.origin.state.value).lgas.map(
                (lgas, i) => {
                  return (
                    <MenuItem
                      key={`${lgas} - ${i}`}
                      value={
                        NaijaStates.lgas(stepTwo.origin.state.value).lgas[i]
                      }
                    >
                      {lgas}
                    </MenuItem>
                  );
                }
              )}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepTwo.origin.localGovt.isFilled &&
              "Local Govt. Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <Typography m="1rem 0">HOME ADDRESS</Typography>

        <TextField
          id="Street"
          label="Street"
          name="street"
          value={stepTwo.homeAdd.street.value}
          onChange={stepTwoChangeHandler("homeAdd")}
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepTwo.homeAdd.street.isFilled
          }
          helperText={
            nextClicked[activeStep + 1] &&
            !stepTwo.homeAdd.street.isFilled &&
            "Street Cannot Be Empty"
          }
        />
        <TextField
          id="City/Town"
          label="City/Town"
          name="city"
          value={stepTwo.homeAdd.city.value}
          onChange={stepTwoChangeHandler("homeAdd")}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepTwo.homeAdd.city.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepTwo.homeAdd.city.isFilled &&
            "City Cannot Be Empty"
          }
        />

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepTwo.homeAdd.state.isFilled}
        >
          <InputLabel id="State">State</InputLabel>
          <Select
            labelId="State"
            id="State"
            name="state"
            value={stepTwo.homeAdd.state.value}
            onChange={stepTwoChangeHandler("homeAdd")}
          >
            {state.map((stat, i) => {
              return (
                <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                  {stat}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepTwo.homeAdd.state.isFilled &&
              "state Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepTwo.homeAdd.localGovt.isFilled
          }
        >
          <InputLabel id="Local Govt.">Local Govt.</InputLabel>
          <Select
            labelId="Local Govt."
            id="Local Govt."
            name="localGovt"
            value={stepTwo.homeAdd.localGovt.value}
            onChange={stepTwoChangeHandler("homeAdd")}
          >
            {stepTwo.homeAdd.state.isFilled &&
              NaijaStates.lgas(stepTwo.homeAdd.state.value).lgas.map(
                (lgas, i) => {
                  return (
                    <MenuItem
                      key={`${lgas} - ${i}`}
                      value={
                        NaijaStates.lgas(stepTwo.homeAdd.state.value).lgas[i]
                      }
                    >
                      {lgas}
                    </MenuItem>
                  );
                }
              )}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepTwo.homeAdd.localGovt.isFilled &&
              "Local Govt. Cannot Be Empty"}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );

  const step3 = (
    <Box>
      <Typography>MEANS OF IDENTIFICATION</Typography>

      <Box m="1.5rem 0">
        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepThree.idType.isFilled}
        >
          <InputLabel id="Id Card">Id Card</InputLabel>
          <Select
            labelId="Id Card"
            id="Id Card"
            name="idType"
            value={stepThree.idType.value}
            onChange={stepThreeChangeHandler}
          >
            <MenuItem value={"Nin Slip"}>Nin Slip</MenuItem>
            <MenuItem value={"National Id Card"}>National Id Card</MenuItem>
            <MenuItem value={"Voters Card"}>Voters Card</MenuItem>
            <MenuItem value={"Driving License"}>Driving License</MenuItem>
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepThree.idType.isFilled &&
              "Id Card Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <TextField
          id="Identification Number"
          label="Identification Number"
          name="idNum"
          value={stepThree.idNum.value}
          onChange={stepThreeChangeHandler}
          InputProps={{
            inputComponent: NumericInputFormat,
          }}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepThree.idNum.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepThree.idNum.isFilled &&
            "Id Number Cannot Be Empty"
          }
        />
        <TextField
          id="BVN"
          label="BVN"
          name="bvn"
          value={stepThree.bvn.value}
          onChange={stepThreeChangeHandler}
          InputProps={{
            inputComponent: NumericInputFormat,
          }}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepThree.bvn.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepThree.bvn.isFilled &&
            "BVN Cannot Be Empty"
          }
        />

        <PhotoCard
          title="Passport Photography"
          refName={passportInput}
          name="passport"
          changeHandler={stepThreeChangeHandler}
          url={stepThree.passport.value}
          error={nextClicked[activeStep + 1] && !stepThree.passport.isFilled}
          errMsg="Passport Is Required "
        />

        <PhotoCard
          title="ID Card Photography"
          refName={idPhotoInput}
          name="idPhoto"
          changeHandler={stepThreeChangeHandler}
          url={stepThree.idPhoto.value}
          error={nextClicked[activeStep + 1] && !stepThree.idPhoto.isFilled}
          errMsg="Id Card Photo Is Required"
        />
      </Box>
    </Box>
  );

  const step4 = (
    <Box>
      <Typography>BUSINESS DETAILS</Typography>

      <Box m="1.5rem 0">
        <TextField
          id="Business Name"
          label="Business Name"
          name="name"
          value={stepFour.name.value}
          onChange={stepFourChangeHandler()}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepFour.name.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFour.name.isFilled &&
            "Bussiness Name Cannot Be Empty"
          }
        />

        <Typography>BUSINESS LOCATION</Typography>

        <TextField
          id="Street"
          label="Street"
          name="street"
          value={stepFour.location.street.value}
          onChange={stepFourChangeHandler("location")}
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFour.location.street.isFilled
          }
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFour.location.street.isFilled &&
            "Street Cannot Be Empty"
          }
        />

        <TextField
          id="City/Town"
          label="City/Town"
          name="city"
          value={stepFour.location.city.value}
          onChange={stepFourChangeHandler("location")}
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFour.location.city.isFilled
          }
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFour.location.city.isFilled &&
            "City Cannot Be Empty"
          }
        />
        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFour.location.state.isFilled
          }
        >
          <InputLabel id="State">State</InputLabel>
          <Select
            labelId="State"
            id="State"
            name="state"
            value={stepFour.location.state.value}
            onChange={stepFourChangeHandler("location")}
          >
            {state.map((stat, i) => {
              return (
                <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                  {stat}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepFour.location.state.isFilled &&
              "State Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFour.location.localGovt.isFilled
          }
        >
          <InputLabel id="Local Govt.">Local Govt.</InputLabel>
          <Select
            labelId="Local Govt."
            id="Local Govt."
            name="localGovt"
            value={stepFour.location.localGovt.value}
            onChange={stepFourChangeHandler("location")}
          >
            {stepFour.location.state.isFilled &&
              NaijaStates.lgas(stepFour.location.state.value).lgas.map(
                (lgas, i) => {
                  return (
                    <MenuItem
                      key={`${lgas} - ${i}`}
                      value={
                        NaijaStates.lgas(stepFour.location.state.value).lgas[i]
                      }
                    >
                      {lgas}
                    </MenuItem>
                  );
                }
              )}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepFour.location.localGovt.isFilled &&
              "Local Govt. Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <PhotoCard
          title="Signature photography (white background)"
          refName={signatureInput}
          name="signature"
          changeHandler={stepFourChangeHandler()}
          url={stepFour.signature.value}
          error={nextClicked[activeStep + 1] && !stepFour.signature.isFilled}
          errMsg="Signature Photo Is Required"
        />
      </Box>
    </Box>
  );

  const step5 = (
    <Box>
      <Typography>NEXT OF KIN DETAILS</Typography>

      <Box m="1.5rem 0">
        <TextField
          id="Full Name"
          label="Full Name"
          name="name"
          value={stepFive.name.value}
          onChange={stepFiveChangeHandler()}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepFive.name.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFive.name.isFilled &&
            "Name Cannot Be Empty"
          }
        />
        <TextField
          id="Phone Number"
          label="Phone Number"
          name="number"
          InputProps={{
            inputComponent: PhoneFormat,
          }}
          value={stepFive.number.value}
          onChange={stepFiveChangeHandler()}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !!phoneErrMsgNxtKin}
          helperText={nextClicked[activeStep + 1] && !!phoneErrMsgNxtKin}
        />

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepFive.relationship.isFilled}
        >
          <InputLabel id="Relationship">Relationship</InputLabel>
          <Select
            labelId="Relationship"
            id="Relationship"
            name="relationship"
            value={stepFive.relationship.value}
            onChange={stepFiveChangeHandler()}
          >
            <MenuItem value={"brother"}>Brother</MenuItem>
            <MenuItem value={"sister"}>Sister</MenuItem>
            <MenuItem value={"wife"}>Wife</MenuItem>
            <MenuItem value={"husband"}>Husband</MenuItem>
            <MenuItem value={"son"}>Son</MenuItem>
            <MenuItem value={"daughter"}>Daughter</MenuItem>
            <MenuItem value={"mother"}>Mother</MenuItem>
            <MenuItem value={"father"}>Father</MenuItem>
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepFive.relationship.isFilled &&
              "Relationship Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <DatePicker
          name="dob"
          label="Date of Birth"
          value={stepFive.dob.value}
          changeHandler={stepFiveChangeHandler()}
          error={nextClicked[activeStep + 1] && !stepFive.dob.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFive.dob.isFilled &&
            "Date Of Birth Cannot Be Empty"
          }
        />

        <TextField
          id="Email Address"
          label="Email Address"
          name="email"
          value={stepFive.email.value}
          onChange={stepFiveChangeHandler()}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !!emailErrMsgNxtKin}
          helperText={nextClicked[activeStep + 1] && emailErrMsgNxtKin}
        />

        <Typography>HOME ADDRESS</Typography>

        <TextField
          id="Street"
          label="Street"
          name="street"
          value={stepFive.homeAdd.street.value}
          onChange={stepFiveChangeHandler("homeAdd")}
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFive.homeAdd.street.isFilled
          }
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFive.homeAdd.street.isFilled &&
            "Street Cannot Be Empty"
          }
        />

        <TextField
          id="City/Town"
          label="City/Town"
          name="city"
          value={stepFive.homeAdd.city.value}
          onChange={stepFiveChangeHandler("homeAdd")}
          variant="filled"
          margin="dense"
          fullWidth
          error={nextClicked[activeStep + 1] && !stepFive.homeAdd.city.isFilled}
          helperText={
            nextClicked[activeStep + 1] &&
            !stepFive.homeAdd.city.isFilled &&
            "City Cannot Be Empty"
          }
        />

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFive.homeAdd.state.isFilled
          }
        >
          <InputLabel id="State">State</InputLabel>
          <Select
            labelId="State"
            id="State"
            name="state"
            value={stepFive.homeAdd.state.value}
            onChange={stepFiveChangeHandler("homeAdd")}
          >
            {state.map((stat, i) => {
              return (
                <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                  {stat}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepFive.homeAdd.state.isFilled &&
              "State Cannot Be Empty"}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="filled"
          margin="dense"
          fullWidth
          error={
            nextClicked[activeStep + 1] && !stepFive.homeAdd.localGovt.isFilled
          }
        >
          <InputLabel id="Local Govt.">Local Govt.</InputLabel>
          <Select
            labelId="Local Govt."
            id="Local Govt."
            name="localGovt"
            value={stepFive.homeAdd.localGovt.value}
            onChange={stepFiveChangeHandler("homeAdd")}
          >
            {stepFive.homeAdd.state.isFilled &&
              NaijaStates.lgas(stepFive.homeAdd.state.value).lgas.map(
                (lgas, i) => {
                  return (
                    <MenuItem
                      key={`${lgas} - ${i}`}
                      value={
                        NaijaStates.lgas(stepFive.homeAdd.state.value).lgas[i]
                      }
                    >
                      {lgas}
                    </MenuItem>
                  );
                }
              )}
          </Select>
          <FormHelperText>
            {nextClicked[activeStep + 1] &&
              !stepFive.homeAdd.localGovt.isFilled &&
              "Local Govt. Cannot Be Empty"}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );

  const step = {
    1: step1,
    2: step2,
    3: step3,
    4: step4,
    5: step5,
  };

  return (
    <WrapLayout>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ mb: "1rem" }}>
          <Typography
            variant="h4"
            component="h3"
            sx={{ textAlign: "center" }}
            ref={topText}
          >
            Terminal Form
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} sx={{ width: "80%", m: "0 auto" }}>
            {steps.map((label, index) => (
              <Step key={`${label}-${index}`}>
                <StepLabel color="inherit"></StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "1rem",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: "0.5rem",
                  }}
                >
                  <Typography variant="h5" component="h3">
                    All Done
                  </Typography>
                  <DoneRounded sx={{ ml: "0.5rem" }} />
                </Box>
                <Typography variant="p" component="p">
                  Thanks for your request. A representative will reach out to
                  for further inquires and for the delivery of your terminal.{" "}
                  <strong>Thanks for your patience.</strong>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleDone} variant="contained">
                  Done
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box sx={{ mt: 2, mb: 1 }}>{step[activeStep + 1]}</Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  startIcon={<WestRounded />}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={handleNext(activeStep + 1)}
                  variant="contained"
                  endIcon={activeStep !== steps.length - 1 && <EastRounded />}
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default TerminalRequestForm;
