import { Done, DoneRounded } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { useAddNewSuggestionMutation } from "../store/suggestion/suggestionApiSlice";

const SuggestionForm = () => {
  const { id } = useAuth();
  const errorMsgRef = useRef();

  const [suggest, setSuggest] = useState({
    title: "",
    suggestion: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const canSave = !!suggest.title && !!suggest.suggestion;

  const [addNewSuggestion, { isSuccess, isError, error }] =
    useAddNewSuggestionMutation();

  const suggestChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSuggest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);
    if (canSave) {
      setSubmitLoading(true);
      await addNewSuggestion({ id, ...suggest });
    }
  };

  const submittedHandler = () => {
    setSubmitted(false);
    setSubmitClicked(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setSuggest({
        title: "",
        suggestion: "",
      });
      setErrorMsg("");
      setSubmitted(true);
      setSubmitLoading(false);
    }
    if (isError) {
      setSubmitLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [isSuccess, isError, error]);

  if (submitted) {
    return (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Box component="span">
          <DoneRounded sx={{ fontSize: "3rem" }} color="success" />
        </Box>
        <Box mb="1rem">
          <Typography>
            Thanks for helping us improve and serve you better
          </Typography>
        </Box>
        <Button variant="contained" onClick={submittedHandler}>
          Done
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: "2rem" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography variant="h5" component="h2" sx={{ pb: "0.3rem" }}>
          Let Your Concern Be Known
        </Typography>
        <Typography variant="body1" component="p">
          write to the management about anything you would like to be amended or
          added. <strong>Your feedback are deeply appreciated</strong>
        </Typography>
      </Box>

      <Box ref={errorMsgRef} sx={{ width: "100%", mb: "1rem" }}>
        {errorMsg && (
          <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
            {errorMsg}
          </Alert>
        )}
      </Box>

      <Box component="form" noValidate>
        <TextField
          label="Title"
          name="title"
          margin="dense"
          onChange={suggestChangeHandler}
          value={suggest.title}
          fullWidth
          error={submitClicked && !suggest.title}
          helperText={
            submitClicked && !suggest.title && "Title Cannot Be Empty"
          }
        />

        <TextField
          label="Suggestion"
          placeholder="Suggestion...."
          name="suggestion"
          margin="dense"
          onChange={suggestChangeHandler}
          value={suggest.suggestion}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          error={submitClicked && !suggest.suggestion}
          helperText={
            submitClicked && !suggest.suggestion && "Suggestion Cannot Be Empty"
          }
        />

        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{ marginTop: "1rem" }}
          onClick={submitHandler}
          endIcon={
            submitLoading ? (
              <CircularProgress
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
                size={"1rem"}
              />
            ) : (
              <Done />
            )
          }
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SuggestionForm;
