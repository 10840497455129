import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../store/api/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "../store/auth/authSlice";
// import { configureStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk'/

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false,
});

setupListeners(store.dispatch);
