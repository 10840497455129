import { forwardRef } from "react";
import { PatternFormat } from "react-number-format";

const PhoneFormat = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      format="#### ### ####"
    />
  );
});

// const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
//     const { onChange, ...other } = props;
//     return (
//       <IMaskInput
//         {...other}
//         mask="(#00) 000-0000"
//         definitions={{
//           '#': /[1-9]/,
//         }}
//         inputRef={ref}
//         onAccept={(value) => onChange({ target: { name: props.name, value } })}
//         overwrite
//       />
//     );
//   });

export default PhoneFormat;
