import { useNavigate } from "react-router-dom";

import { Box, Typography, Divider, Card, CardContent } from "@mui/material";
let thousand = new Intl.NumberFormat("en-US");

const AccountCard = ({ account, accountId, showAccountant }) => {
  const navigate = useNavigate();
  const accountClickHandler = () => {
    navigate(`/account/${accountId}`);
  };

  return (
    <Box
      sx={{ width: "90%", margin: "0 auto", cursor: "pointer" }}
      onClick={accountClickHandler}
    >
      <Card sx={{ mb: "1.2rem", width: "100%" }}>
        <CardContent>
          <Divider>
            <Typography variant="body2" color="primary">
              {account.terminal.location.toUpperCase()}
            </Typography>
          </Divider>
          {showAccountant && (
            <Typography variant="h6" color="blueviolet">
              Accountant:{" "}
              {account.accountant.toUpperCase() === "TOSIN"
                ? "SAM"
                : account.accountant.toUpperCase()}
            </Typography>
          )}
          <Typography variant="h6">
            NAME: {account.cashier.name.toUpperCase()}
          </Typography>
          <Typography variant="h6">
            CASHOUT: {thousand.format(account.cashOut)}
          </Typography>
          <Typography variant="h6">
            FUND: {thousand.format(account.fund)}
          </Typography>
          <Typography variant="h6" color="error">
            TERMINAL: {thousand.format(account.withdraw)}
          </Typography>
          <Typography variant="h6" color="error">
            TRANS PRO: {thousand.format(account.transPro)}
          </Typography>
          <Typography variant="h6" color="error">
            TRANS ACC: {thousand.format(account.transAcc)}
          </Typography>
          <Typography variant="h6" color="error">
            POS TRANS: {thousand.format(account.posTrans)}
          </Typography>
          <Typography variant="h6">
            DEPOSIT: {thousand.format(account.deposit)}
          </Typography>
          <Typography variant="h6" color="error">
            DEDUCT {thousand.format(account.deduct)}
          </Typography>
          <Typography variant="h6" color="error">
            CASH IN: {thousand.format(account.cashIn)}
          </Typography>
          <Typography variant="h6">
            CL BAL: {thousand.format(account.closeBal)}
          </Typography>
          {account.shortage && (
            <Typography variant="h6" color="error">
              SHORTAGE: {`${thousand.format(account.shortage?.amount)}`}
            </Typography>
          )}
          <Typography variant="h5" color="success.main">
            CASH:&nbsp;
            {thousand.format(
              account.shortage
                ? account.cashIn - account?.shortage?.amount
                : account.cashIn
            )}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccountCard;
