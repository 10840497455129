import { Box, Typography, Link, Button } from '@mui/material';
const Page404 = () => {
  return (
    <Box sx={{ m: '2rem auto', textAlign: 'center' }}>
      <Typography variant="h1" component="h2">
        404
      </Typography>
      <Typography variant="h6" component="h2">
        Sorry, Page Not Found
      </Typography>
      <Link  href='/' underline='none'  >
        <Button variant="contained" sx={{ mt: '1rem' }} >
          Return Home
        </Button>
      </Link>
    </Box>
  );
};

export default Page404;