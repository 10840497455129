import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";

function CopyToClip({ value }) {
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [buttonText, setButtonText] = useState("Copy");

  const enterHandler = () => {
    setShowCopyButton(true);
  };

  const leaveHandler = () => {
    setButtonText("Copy");
    setShowCopyButton(false);
  };

  const copyHandler = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      setButtonText("Copied");
    }
  };

  const CopyField = (props) => (
    <Box
      onMouseEnter={enterHandler}
      onMouseLeave={leaveHandler}
      sx={{ position: "relative" }}
    >
      <TextField
        variant="standard"
        sx={{
          backgroundColor: "beige",
          borderRadius: "4px",
          padding: "0.4rem 0.5rem",
          width: "100%",
          "&:hover": {
            border: "1px solid",
            borderColor: (theme) => theme.palette.primary.main,
          },
        }}
        InputProps={{
          disableUnderline: true,
        }}
        value={value?.toUpperCase()}
      ></TextField>
      {showCopyButton && (
        <Button
          onClick={copyHandler}
          variant="outlined"
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            backgroundColor: (theme) => theme.palette.primary.container,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );

  return <CopyField />;
}

export default CopyToClip;
