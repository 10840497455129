import { useGetTerminalRequestsQuery } from "../store/terminalRequest/terminalRequestApiSlice";
import RequestCard from "./RequestCard";
import { Box, CircularProgress, Typography } from "@mui/material";

const RequestInfo = () => {
  const {
    data: requests,
    isLoading,
    isSuccess,
    isError,
  } = useGetTerminalRequestsQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Requests </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = requests;

    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4">
            No Terminal Request At The Moment{" "}
          </Typography>
          <Typography variant="p" component="p">
            Check back later
          </Typography>
        </Box>
      );
    }

    return ids.map((requestId) => (
      <RequestCard
        key={requestId}
        request={entities[requestId]}
        id={requestId}
      />
    ));
  }
};

export default RequestInfo;
