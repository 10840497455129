import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const shortageAdapter = createEntityAdapter({});

const initialState = shortageAdapter.getInitialState();

export const shortageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShortages: builder.query({
      query: () => ({
        url: "/shortage",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedShortages = responseData.map((shortage) => {
          shortage.id = shortage._id;
          return shortage;
        });
        return shortageAdapter.setAll(initialState, loadedShortages);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Shortage", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Shortage", id })),
          ];
        } else return [{ type: "Shortage", id: "LIST" }];
      },
    }),
    addNewShortage: builder.mutation({
      query: (initialShortage) => ({
        url: "/shortage",
        method: "POST",
        body: {
          ...initialShortage,
        },
      }),
      invalidatesTags: [{ type: "Shortage", id: "LIST" }],
    }),
    updateShortage: builder.mutation({
      query: (initialShortage) => ({
        url: "/shortage",
        method: "PATCH",
        body: {
          ...initialShortage,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Shortage", id: arg.id },
      ],
    }),
    deleteShortage: builder.mutation({
      query: (id) => ({
        url: `/shortage`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Shortage", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetShortagesQuery,
  useAddNewShortageMutation,
  useUpdateShortageMutation,
  useDeleteShortageMutation,
} = shortageApiSlice;

// returns the query result object
export const selectShortagesResult =
  shortageApiSlice.endpoints.getShortages.select();

// creates memoized selector
const selectShortagesData = createSelector(
  selectShortagesResult,
  (ShortagesResult) => ShortagesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllShortages,
  selectById: selectShortageById,
  selectIds: selectShortageIds,
  // Pass in a selector that returns the Shortages slice of state
} = shortageAdapter.getSelectors(
  (state) => selectShortagesData(state) ?? initialState
);
