import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetSuggestionsQuery } from "../store/suggestion/suggestionApiSlice";
import SuggestionCard from "./SuggestionCard";

const SuggestionList = () => {
  const {
    data: suggestion,
    isLoading,
    isSuccess,
    isError,
  } = useGetSuggestionsQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Suggestions </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = suggestion;

    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4"> No Suggestion Yet </Typography>
          <Typography variant="p" component="p">
            Hopefully We Will get some soon.
          </Typography>
        </Box>
      );
    }

    const reversedSuggestion = ids.slice().reverse();
    return reversedSuggestion.map((suggestionId) => (
      <SuggestionCard key={suggestionId} suggestion={entities[suggestionId]} />
    ));
  }
};

export default SuggestionList;
