import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import {
  useAddNewTerminalMutation,
  useUpdateTerminalMutation,
} from "../store/terminal/terminalApiSlice";

const TerminaLForm = ({ initialTerminalData, edit }) => {
  const navigate = useNavigate();
  const errorMsgRef = useRef();

  const [terminalData, setTerminalData] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    setTerminalData(initialTerminalData);
  }, [initialTerminalData]);

  const [addNewTerminal, { isSuccess, isError, error }] =
    useAddNewTerminalMutation();

  const [
    updateTerminal,
    { isSuccess: updateSuccess, isError: updateIsError, error: updateError },
  ] = useUpdateTerminalMutation();

  useEffect(() => {
    if (isSuccess || updateSuccess) {
      setTerminalData(initialTerminalData);
      navigate("/admin/terminal");
    }
    if (isError) {
      setLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
    if (updateIsError) {
      setLoading(false);
      setErrorMsg(updateError.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [
    isSuccess,
    updateSuccess,
    navigate,
    initialTerminalData,
    isError,
    error,
    updateIsError,
    updateError,
  ]);

  const canSave = !!terminalData.name && !!terminalData.location;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const inputChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTerminalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const userSubmitHandler = async (e) => {
    setSubmitClicked(true);
    e.preventDefault();

    if (canSave) {
      setLoading(true);
      if (edit) {
        await updateTerminal({
          ...terminalData,
        });
      } else {
        await addNewTerminal({
          ...terminalData,
        });
      }
    }
  };

  if (!terminalData) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={userSubmitHandler}
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "1rem auto 1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h4" component="h2" sx={{ mb: "1rem" }}>
          {edit ? "Update" : "Create"} Terminal
        </Typography>
      </Box>

      <Box ref={errorMsgRef} sx={{ width: "100%" }}>
        {errorMsg && (
          <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
            {errorMsg}
          </Alert>
        )}
      </Box>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !terminalData.name}
      >
        <InputLabel htmlFor="Name">Name</InputLabel>
        <OutlinedInput
          id="Name"
          name="name"
          value={terminalData.name}
          onChange={inputChangeHandler}
          label="Name"
        />
        <FormHelperText>
          {submitClicked && !terminalData.name && "Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !terminalData.location}
      >
        <InputLabel htmlFor="Location">Location</InputLabel>
        <OutlinedInput
          id="Location"
          name="location"
          value={terminalData.location}
          onChange={inputChangeHandler}
          label="Location"
        />
        <FormHelperText>
          {submitClicked &&
            !terminalData.location &&
            "Location Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ marginTop: "1rem" }}
        endIcon={
          loading ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
              size={"1rem"}
            />
          ) : (
            <EastRounded />
          )
        }
      >
        {edit ? "Update" : "Create"}
      </Button>
    </Box>
  );
};

export default TerminaLForm;
