import CashierCard from "./CashierCard";
import { useGetCashiersQuery } from "../store/cashier/cashierApiSlice";
import { Box, CircularProgress, Typography } from "@mui/material";

const CashierInfo = () => {
  const {
    data: cashiers,
    isLoading,
    isSuccess,
    isError,
  } = useGetCashiersQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Cashiers </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = cashiers;
    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4"> No Cashier Yet </Typography>
          <Typography variant="p" component="p">
            Try adding new cashier
          </Typography>
        </Box>
      );
    }

    const reversedIds = ids.slice().reverse();
    return reversedIds.map((cashierId) => (
      <CashierCard key={cashierId} cashier={entities[cashierId]} />
    ));
  }
};

export default CashierInfo;
