import {
  useAddNewAccountMutation,
  useUpdateAccountMutation,
} from "../store/account/accountApiSlice";
import useAuth from "../hooks/useAuth";
import NumberFormat from "./NumberFormat";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import uniqid from "uniqid";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItem,
  Divider,
  TextField,
} from "@mui/material";
import {
  ArrowRightAlt,
  EastRounded,
  ExpandMore,
  ExpandLess,
  Delete,
} from "@mui/icons-material";

import React from "react";

const AccountForm = ({ data, edit }) => {
  const { terminals, cashiers, ratings, initialData } = data;
  const [accountData, setAccountData] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({});
  const { id } = useAuth();

  const canSave = [
    accountData.cashier,
    accountData.terminal,
    accountData.rating,
    accountData.cashOut,
    !loading,
  ].every(Boolean);

  useEffect(() => {
    setAccountData(initialData);
  }, [initialData]);

  const thousand = new Intl.NumberFormat("en-US");
  const navigate = useNavigate();

  const [addNewAccount, { data: newAcc, isSuccess }] =
    useAddNewAccountMutation();

  const [updateAccount, { data: updatedAcc, isSuccess: updateSuccess }] =
    useUpdateAccountMutation();

  useEffect(() => {
    if (isSuccess) {
      setAccountData(initialData);
      navigate(`/account/${newAcc.id}`);
    }
  }, [isSuccess, navigate, initialData, newAcc]);

  useEffect(() => {
    if (updateSuccess) {
      navigate(`/account/${updatedAcc.id}`);
    }
  }, [updateSuccess, navigate, initialData, updatedAcc]);

  const [errorMsg, setErrorMsg] = useState("");

  const changeHandler = (event) => {
    setAccountData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const nextValueHandler = (name) => (event) => {
    const arr = name.concat("Arr");
    setAccountData((prevState) => ({
      ...prevState,
      [arr]: [
        ...prevState[arr],
        {
          value: prevState[name],
          key: uniqid.time(),
        },
      ],
      [name]: "",
    }));
  };

  const transDeleteHandler = (key, propArray) => {
    setAccountData((prevState) => ({
      ...prevState,
      [propArray]: [...prevState[propArray].filter((t) => t.key !== key)],
    }));
  };

  const transList = (array, propArray) => {
    return array.map((trans) => (
      <>
        <ListItem
          key={trans.key}
          sx={{ pl: 4 }}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                transDeleteHandler(trans.key, propArray);
              }}
            >
              <Delete />
            </IconButton>
          }
        >
          <ListItemText primary={`₦ ${thousand.format(trans.value)}`} />
        </ListItem>
        <Divider />
      </>
    ));
  };

  const handleClick = (element) => {
    setOpen((prevState) => ({
      ...prevState,
      [element]: !prevState[element],
    }));
  };

  const getDataValue = (arrName) => {
    if (!arrName) return [];
    return accountData[arrName].map(({ value }) => value);
  };

  const userSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    const withdrawArr = getDataValue("withdrawArr");
    const transProArr = getDataValue("transProArr");
    const transAccArr = getDataValue("transAccArr");
    const posTransArr = getDataValue("posTransArr");
    const depositArr = getDataValue("depositArr");
    const airtimeArr = getDataValue("airtimeArr");
    const billArr = getDataValue("billArr");
    const fundArr = getDataValue("fundArr");
    const deductArr = getDataValue("deductArr");

    if (canSave) {
      if (edit) {
        await updateAccount({
          accountant: id,
          cashier: accountData.cashier,
          id: accountData.id,
          terminal: accountData.terminal,
          rating: accountData.rating,
          cashOut: accountData.cashOut,
          withdrawArr,
          transProArr,
          transAccArr,
          posTransArr,
          depositArr,
          airtimeArr,
          billArr,
          fundArr,
          deductArr,
        });
        return;
      }
      await addNewAccount({
        accountant: id,
        cashier: accountData.cashier,
        terminal: accountData.terminal,
        rating: accountData.rating,
        cashOut: accountData.cashOut,
        withdrawArr,
        transProArr,
        transAccArr,
        posTransArr,
        depositArr,
        airtimeArr,
        billArr,
        fundArr,
        deductArr,
      });
    }
    setLoading(false);
  };

  if (accountData) {
    return (
      <Box
        component="form"
        onSubmit={userSubmitHandler}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        noValidate
      >
        {
          <Box sx={{ width: "100%", mb: ".5rem" }}>
            {errorMsg && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {errorMsg}
              </Alert>
            )}
          </Box>
        }

        <Box sx={{ mb: "0.5rem" }}>
          <Typography variant="h4" component="h2">
            {edit ? "Update" : "New"} Account
          </Typography>
        </Box>

        <FormControl fullWidth margin="dense">
          <InputLabel htmlFor="cashier">Cashier</InputLabel>
          <Select
            id="cashier"
            name="cashier"
            labelId="cashier"
            value={accountData.cashier}
            label="Cashier"
            onChange={changeHandler}
          >
            {cashiers.map((cashier) => (
              <MenuItem value={cashier.id} key={cashier.id}>
                {cashier.nickName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel htmlFor="s">Terminal</InputLabel>
          <Select
            id="terminal"
            name="terminal"
            labelId="terminal"
            value={accountData.terminal}
            label="Terminal"
            onChange={changeHandler}
          >
            {terminals.map((terminal) => (
              <MenuItem value={terminal.id} key={terminal.id}>
                {terminal.location.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel htmlFor="rating">Rating</InputLabel>
          <Select
            id="rating"
            name="rating"
            labelId="rating"
            value={accountData.rating}
            label="Rating"
            onChange={changeHandler}
          >
            {ratings.map((rating) => (
              <MenuItem value={rating.value} key={rating.key}>
                {`${rating.value.toUpperCase()} STAR`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            label="Cash Out"
            value={accountData.cashOut}
            name="cashOut"
            onChange={changeHandler}
            id="cashOut"
            InputProps={{
              inputComponent: NumberFormat,
            }}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            label="Withdraw"
            name="withdraw"
            value={accountData.withdraw}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.withdraw && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("withdraw")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.withdrawArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("withdraw")}>
              <ListItemText
                primary={`Withdraws (${accountData.withdrawArr?.length})`}
              />
              {open.withdraw ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.withdraw} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.withdrawArr, "withdrawArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Transfer Providus"
            name="transPro"
            value={accountData.transPro}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.transPro && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("transPro")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.transProArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("transPro")}>
              <ListItemText
                primary={`Tranfers Providus (${accountData.transProArr?.length})`}
              />
              {open.transPro ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.transPro} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.transProArr, "transProArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Transfer Access"
            name="transAcc"
            value={accountData.transAcc}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.transAcc && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("transAcc")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.transAccArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("transAcc")}>
              <ListItemText
                primary={`Tranfers Access (${accountData.transAccArr?.length})`}
              />
              {open.transAcc ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.transAcc} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.transAccArr, "transAccArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="POS Transfer "
            name="posTrans"
            value={accountData.posTrans}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.posTrans && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("posTrans")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.posTransArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("posTrans")}>
              <ListItemText
                primary={`POS Tranfers (${accountData.posTransArr?.length})`}
              />
              {open.posTrans ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.posTrans} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.posTransArr, "posTransArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Deposit"
            name="deposit"
            value={accountData.deposit}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.deposit && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("deposit")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.depositArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("deposit")}>
              <ListItemText
                primary={`Deposits (${accountData.depositArr?.length})`}
              />
              {open.deposit ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.deposit} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.depositArr, "depositArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Airtime"
            name="airtime"
            value={accountData.airtime}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.airtime && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("airtime")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.airtimeArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("airtime")}>
              <ListItemText
                primary={`Airtime (${accountData.airtimeArr?.length})`}
              />
              {open.airtime ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.airtime} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.airtimeArr, "airtimeArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Bill"
            name="bill"
            value={accountData.bill}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.bill && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("bill")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.billArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("bill")}>
              <ListItemText
                primary={`Bills (${accountData.billArr?.length})`}
              />
              {open.bill ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.bill} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.billArr, "billArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Fund"
            name="fund"
            value={accountData.fund}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.fund && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("fund")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.fundArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("fund")}>
              <ListItemText primary={`Fund (${accountData.fundArr?.length})`} />
              {open.fund ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.fund} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.fundArr, "fundArr")}
              </List>
            </Collapse>
          </List>
        )}

        <FormControl fullWidth margin="dense">
          <TextField
            label="Deduct"
            name="deduct"
            value={accountData.deduct}
            onChange={changeHandler}
            InputProps={{
              inputComponent: NumberFormat,
              endAdornment: accountData.deduct && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={nextValueHandler("deduct")}
                >
                  <Typography variant="h6" sx={{ lineHeight: "1" }}>
                    next
                  </Typography>
                  <ArrowRightAlt />
                </Button>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        {!!accountData.deductArr?.length && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "common.outline",
              borderRadius: "4px",
              margin: "8px 0 4px",
            }}
          >
            <ListItemButton onClick={() => handleClick("deduct")}>
              <ListItemText
                primary={`Deducts (${accountData.deductArr?.length})`}
              />
              {open.deduct ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.deduct} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{
                  margin: "0 1rem",
                  bgcolor: "primary.contrastText",
                  borderRadius: "4px",
                }}
                disablePadding
              >
                {transList(accountData.deductArr, "deductArr")}
              </List>
            </Collapse>
          </List>
        )}

        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ marginTop: "1rem" }}
          endIcon={
            loading ? (
              <CircularProgress
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
                size={"1rem"}
              />
            ) : (
              <EastRounded />
            )
          }
          disabled={!canSave}
        >
          {edit ? "Update" : "Submit"} Account
        </Button>
      </Box>
    );
  }
};

export default AccountForm;
