import { useState } from "react";
import { NavigateNext, WestRounded } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Card,
  CardActions,
  Button,
} from "@mui/material";
import AccountantForm from "../AccountantForm";
import AccountantFormC from "../AccountantFormC";
import WrapLayout from "../WrapLayout";
const NewAccountant = () => {
  const [source, setSource] = useState("");

  const cashierBtn = () => {
    setSource("cashier");
  };
  const accountantBtn = () => {
    setSource("new");
  };
  const resetSource = () => {
    setSource("");
  };

  const newAccountantData = {
    title: "",
    firstName: "",
    lastName: "",
    nickName: "",
    email: "",
    role: "",
    phone: "",
    permission: "",
    password: "",
    street: "",
    city: "",
    state: "",
    localGov: "",
    avatar: "",
    avatarName: "No profile photo",
    source,
  };

  const guarantorData = {
    title: "",
    firstName: "",
    lastName: "",
    relationship: "",
    natureOfBusiness: "",
    street: "",
    city: "",
    state: "",
    localGov: "",
    avatar: "",
    avatarName: "No profile photo",
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "1rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/admin/accountant">
              Accountants
            </Link>
            <Typography color="text.primary">New </Typography>
          </Breadcrumbs>
        </Box>
        {source && (
          <Box sx={{ alignSelf: "start" }}>
            <Button sx={{ display: "flex" }} onClick={resetSource}>
              <WestRounded sx={{ marginRight: "0.5rem" }} /> Back
            </Button>
          </Box>
        )}
        {!source && (
          <Box sx={{ maxWidth: "80%", width: "80%", mt: "1rem" }}>
            <Card sx={{ p: "1rem" }}>
              <Typography mb="1rem" variant="h5">
                Create Accountant
              </Typography>
              <CardActions>
                <Button
                  sx={{ width: "100%" }}
                  size="large"
                  variant="contained"
                  onClick={cashierBtn}
                >
                  From Cashiers
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  sx={{ width: "100%" }}
                  size="large"
                  variant="contained"
                  onClick={accountantBtn}
                >
                  New Accountant
                </Button>
              </CardActions>
            </Card>
          </Box>
        )}
        <Box sx={{ maxWidth: "100%", width: "100%" }}>
          {source === "cashier" && <AccountantFormC source={source} />}
          {source === "new" && (
            <AccountantForm
              initialAccountantData={newAccountantData}
              initialGuarantorData={guarantorData}
            />
          )}
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default NewAccountant;
