import TerminalForm from "../TerminalForm";
import { Box, Typography, Breadcrumbs, Link } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const NewTerminal = () => {
  const terminalData = {
    name: "",
    location: "",
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/admin/terminal">
              Terminals
            </Link>
            <Typography color="text.primary">Create Terminal</Typography>2
          </Breadcrumbs>
        </Box>
        <TerminalForm initialTerminalData={terminalData} />
      </Box>
    </WrapLayout>
  );
};

export default NewTerminal;
