import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  EastRounded,
  PhotoCamera,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import NaijaStates from "naija-state-local-government";
import { useAddNewAccountantMutation } from "../store/accountant/accountantApiSlice";
import PhoneFormat from "./PhoneFormat";

const state = NaijaStates.states();

const AccountantForm = ({ initialAccountantData, initialGuarantorData }) => {
  const navigate = useNavigate();
  const errorMsgRef = useRef();
  const accountantAvatarInput = useRef();
  const guarantorAvatarInput = useRef();

  const [accountantData, setAccountantData] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [guarantorData, setGuarantorData] = useState("");
  const [showPassword, setShowPassword] = useState("");

  useEffect(() => {
    setAccountantData(initialAccountantData);
  }, [initialAccountantData]);

  useEffect(() => {
    setGuarantorData(initialGuarantorData);
  }, [initialGuarantorData]);

  const [addNewAccountant, { isSuccess, isError, error }] =
    useAddNewAccountantMutation();

  const passwordVisibiityHandler = () => {
    setShowPassword(!showPassword);
  };

  const inputPreventDefault = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isSuccess) {
      setAccountantData(initialAccountantData);
      setGuarantorData(initialGuarantorData);
      navigate("/admin/accountant");
    }
    if (isError) {
      setLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [
    isSuccess,
    navigate,
    initialAccountantData,
    initialGuarantorData,
    isError,
    error,
  ]);

  const canSave =
    !!accountantData.title &&
    !!accountantData.firstName &&
    !!accountantData.lastName &&
    !!accountantData.nickName &&
    !!accountantData.phone &&
    !!accountantData.permission &&
    !!accountantData.role &&
    !!accountantData.email &&
    !!accountantData.password &&
    !!accountantData.street &&
    !!accountantData.city &&
    !!accountantData.state &&
    !!accountantData.avatar &&
    !!guarantorData.title &&
    !!guarantorData.firstName &&
    !!guarantorData.lastName &&
    !!guarantorData.phone &&
    !!guarantorData.street &&
    !!guarantorData.city &&
    !!guarantorData.state &&
    !!guarantorData.avatar;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const inputChangeHandler = (cat) => (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "avatar") {
      const image = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        if (cat === "guarantor") {
          setGuarantorData((prevState) => ({
            ...prevState,
            avatarName: image.name,
            [name]: reader.result,
          }));
        } else {
          setAccountantData((prevState) => ({
            ...prevState,
            avatarName: image.name,
            [name]: reader.result,
          }));
        }
      };
    } else {
      if (cat === "guarantor") {
        setGuarantorData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setAccountantData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const validatePhone = (number) => {
    if (String(number).length === 11) {
      return true;
    } else {
      return false;
    }
  };

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const emailErrMsg =
    (!accountantData.email && "Email Cannot Be Empty") ||
    (!validateEmail(accountantData.email) && "Pls enter a valid Email Address");

  const phoneErrMsgAccountant =
    (!accountantData.phone && "Phone Number Cannot Be Empty") ||
    (!validatePhone(accountantData.phone) && "Pls enter a valid Phone Number");

  const phoneErrMsgGuarantor =
    (!guarantorData.phone && "Phone Number Cannot Be Empty") ||
    (!validatePhone(guarantorData.phone) && "Pls enter a valid Phone Number");

  const userSubmitHandler = async (event) => {
    setSubmitClicked(true);
    event.preventDefault();

    if (canSave) {
      setLoading(true);

      await addNewAccountant({
        ...accountantData,
        guarantor: { ...guarantorData },
      });
    }
  };

  if (!accountantData || !guarantorData) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={userSubmitHandler}
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h4" component="h2" sx={{ mb: "1rem" }}>
          Create Accountant
        </Typography>
      </Box>

      <Box ref={errorMsgRef} sx={{ width: "100%" }}>
        {errorMsg && (
          <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
            {errorMsg}
          </Alert>
        )}
      </Box>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !accountantData.title}
      >
        <InputLabel id="Title">Title</InputLabel>
        <Select
          labelId="Title"
          id="Title"
          name="title"
          value={accountantData.title}
          onChange={inputChangeHandler()}
        >
          <MenuItem value={"mr"}>Mr</MenuItem>
          <MenuItem value={"mrs"}>Mrs</MenuItem>
          <MenuItem value={"miss"}>Miss</MenuItem>
          <MenuItem value={"mister"}>Mister</MenuItem>
        </Select>
        <FormHelperText>
          {submitClicked && !accountantData.title && "Title Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.firstName}
      >
        <InputLabel htmlFor="firstName">First Name</InputLabel>
        <OutlinedInput
          id="firstName"
          name="firstName"
          value={accountantData.firstName}
          onChange={inputChangeHandler()}
          label="First Name"
        />
        <FormHelperText>
          {submitClicked &&
            !accountantData.firstName &&
            "First Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.lastName}
      >
        <InputLabel htmlFor="lastName">Last Name(Surname)</InputLabel>
        <OutlinedInput
          id="lastName"
          name="lastName"
          value={accountantData.lastName}
          onChange={inputChangeHandler()}
          label="Last Name(Surname)"
        />
        <FormHelperText>
          {submitClicked &&
            !accountantData.lastName &&
            "Last Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <TextField
        id="Phone Number"
        label="Phone Number"
        name="phone"
        value={accountantData.phone}
        onChange={inputChangeHandler()}
        margin="dense"
        InputProps={{
          inputComponent: PhoneFormat,
        }}
        fullWidth
        error={submitClicked && !!phoneErrMsgAccountant}
        helperText={submitClicked && phoneErrMsgAccountant}
      />

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.nickName}
      >
        <InputLabel htmlFor="nickName">Nickname</InputLabel>
        <OutlinedInput
          id="nickName"
          name="nickName"
          value={accountantData.nickName}
          onChange={inputChangeHandler()}
          label="NickName"
        />
        <FormHelperText>
          {submitClicked &&
            !accountantData.nickName &&
            "Nick Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !accountantData.role}
      >
        <InputLabel id="Role">Role</InputLabel>
        <Select
          labelId="Role"
          id="Role"
          name="role"
          value={accountantData.role}
          onChange={inputChangeHandler()}
        >
          <MenuItem value={"accountant"}>Accountant</MenuItem>
          <MenuItem value={"admin"}>Admin</MenuItem>
          <MenuItem value={"secretary"}>Secretary</MenuItem>
        </Select>
        <FormHelperText>
          {submitClicked && !accountantData.role && "Role Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !accountantData.permission}
      >
        <InputLabel id="Permission">Permission</InputLabel>
        <Select
          labelId="Permission"
          id="Permission"
          name="permission"
          value={accountantData.permission}
          onChange={inputChangeHandler()}
        >
          <MenuItem value={true}>Granted</MenuItem>
          <MenuItem value={false}>Restricted</MenuItem>
        </Select>
        <FormHelperText>
          {submitClicked &&
            !accountantData.permission &&
            "Permission Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <TextField
        id="Email Address"
        label="Email Address"
        name="email"
        value={accountantData.email}
        onChange={inputChangeHandler()}
        margin="dense"
        fullWidth
        error={submitClicked && !!emailErrMsg}
        helperText={submitClicked && emailErrMsg}
      />

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.password}
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          value={accountantData.password}
          onChange={inputChangeHandler()}
          label="password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={passwordVisibiityHandler}
                onMouseDown={inputPreventDefault}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          {submitClicked &&
            !accountantData.password &&
            "Password Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <Box sx={{ width: "100%" }}>
        <Typography sx={{ paddingTop: "0.2rem", opacity: "0.5" }}>
          Home Address
        </Typography>
      </Box>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.street}
      >
        <InputLabel htmlFor="street">Street</InputLabel>
        <OutlinedInput
          id="street"
          value={accountantData.street}
          name="street"
          onChange={inputChangeHandler()}
          label="Street"
        />
        <FormHelperText>
          {submitClicked && !accountantData.street && "Street Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !accountantData.city}
      >
        <InputLabel htmlFor="city">City/Town</InputLabel>
        <OutlinedInput
          id="city"
          name="city"
          value={accountantData.city}
          onChange={inputChangeHandler()}
          label="City/Town"
        />
        <FormHelperText>
          {submitClicked && !accountantData.city && "City Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !accountantData.state}
      >
        <InputLabel id="State">State</InputLabel>
        <Select
          labelId="State"
          id="State"
          name="state"
          value={accountantData.state}
          onChange={inputChangeHandler()}
        >
          {state.map((stat, i) => {
            return (
              <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                {stat}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {submitClicked && !accountantData.state && "State Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !accountantData.localGov}
      >
        <InputLabel id="Local Govt.">Local Govt.</InputLabel>
        <Select
          labelId="Local Govt."
          id="Local Govt."
          name="localGov"
          value={accountantData.localGov}
          onChange={inputChangeHandler()}
        >
          {accountantData.state &&
            NaijaStates.lgas(accountantData.state).lgas.map((lgas, i) => {
              return (
                <MenuItem
                  key={`${lgas} - ${i}`}
                  value={NaijaStates.lgas(accountantData.state).lgas[i]}
                >
                  {lgas}
                </MenuItem>
              );
            })}
        </Select>
        <FormHelperText>
          {submitClicked &&
            !accountantData.localGov &&
            "Local Govt. Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <Box sx={{ width: "100%" }}>
        <FormControl
          fullWidth
          sx={{ flexDirection: "row", alignItems: "center" }}
          margin="dense"
        >
          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            startIcon={<PhotoCamera />}
            onClick={() => accountantAvatarInput.current.click()}
          >
            Choose a photo
          </Button>
          {accountantData.avatarName && (
            <Typography
              sx={{ marginLeft: "0.5rem" }}
              color={submitClicked && !accountantData.avatar && "error"}
            >
              {accountantData.avatarName}
            </Typography>
          )}
          <input
            ref={accountantAvatarInput}
            id="avatar"
            name="avatar"
            type="file"
            accept="image/*"
            onChange={inputChangeHandler()}
            hidden
          />
        </FormControl>

        {submitClicked && !accountantData.avatar && (
          <Box>
            <Typography color="error" variant="p">
              Profile Photo Is Required
            </Typography>
          </Box>
        )}
      </Box>

      {/* guarantor Form */}

      <Box>
        <Typography variant="h4" component="h2" sx={{ m: "0.5rem 0 1rem" }}>
          Guarantor
        </Typography>
      </Box>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !guarantorData.title}
      >
        <InputLabel id="GTitle">Title</InputLabel>
        <Select
          labelId="GTitle"
          id="GTitle"
          name="title"
          value={guarantorData.title}
          onChange={inputChangeHandler("guarantor")}
        >
          <MenuItem value={"mr"}>Mr</MenuItem>
          <MenuItem value={"mrs"}>Mrs</MenuItem>
          <MenuItem value={"miss"}>Miss</MenuItem>
          <MenuItem value={"mister"}>Mister</MenuItem>
        </Select>
        <FormHelperText>
          {submitClicked && !guarantorData.title && "Title Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !guarantorData.firstName}
      >
        <InputLabel htmlFor="GfirstName">First Name</InputLabel>
        <OutlinedInput
          id="GfirstName"
          name="firstName"
          value={guarantorData.firstName}
          onChange={inputChangeHandler("guarantor")}
          label="First Name"
        />
        <FormHelperText>
          {submitClicked &&
            !guarantorData.firstName &&
            "First Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !guarantorData.lastName}
      >
        <InputLabel htmlFor="GlastName">Last Name(Surname)</InputLabel>
        <OutlinedInput
          id="GlastName"
          name="lastName"
          value={guarantorData.lastName}
          onChange={inputChangeHandler("guarantor")}
          label="Last Name(Surname)"
        />
        <FormHelperText>
          {submitClicked &&
            !guarantorData.lastName &&
            "Last Name Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <TextField
        id="GPhone Number"
        label="GPhone Number"
        name="phone"
        value={guarantorData.phone}
        onChange={inputChangeHandler("guarantor")}
        margin="dense"
        InputProps={{
          inputComponent: PhoneFormat,
        }}
        fullWidth
        error={submitClicked && !!phoneErrMsgGuarantor}
        helperText={submitClicked && phoneErrMsgGuarantor}
      />

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !guarantorData.relationship}
      >
        <InputLabel id="GRelationship">Relationship</InputLabel>
        <Select
          labelId="GRelationship"
          id="GRelationship"
          name="relationship"
          value={guarantorData.relationship}
          onChange={inputChangeHandler("guarantor")}
        >
          <MenuItem value={"brother"}>Brother</MenuItem>
          <MenuItem value={"sister"}>Sister</MenuItem>
          <MenuItem value={"wife"}>Wife</MenuItem>
          <MenuItem value={"husband"}>Husband</MenuItem>
          <MenuItem value={"son"}>Son</MenuItem>
          <MenuItem value={"daughter"}>Daughter</MenuItem>
          <MenuItem value={"mother"}>Mother</MenuItem>
          <MenuItem value={"father"}>Father</MenuItem>
        </Select>
        <FormHelperText>
          {submitClicked &&
            !guarantorData.relationship &&
            "Relationship Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !guarantorData.natureOfBusiness}
      >
        <InputLabel htmlFor="Gnature">Nature Of Business</InputLabel>
        <OutlinedInput
          id="Gnature"
          name="natureOfBusiness"
          value={guarantorData.natureOfBusiness}
          onChange={inputChangeHandler("guarantor")}
          label="Nature Of Business"
        />
        <FormHelperText>
          {submitClicked &&
            !guarantorData.natureOfBusiness &&
            "Nature Of Business Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <Box sx={{ width: "100%" }}>
        <Typography sx={{ paddingTop: "0.2rem", opacity: "0.5" }}>
          Home Address
        </Typography>
      </Box>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !guarantorData.street}
      >
        <InputLabel htmlFor="Gstreet">Street</InputLabel>
        <OutlinedInput
          id="Gstreet"
          name="street"
          value={guarantorData.street}
          onChange={inputChangeHandler("guarantor")}
          label="Street"
        />
        <FormHelperText>
          {submitClicked && !guarantorData.street && "Street Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={submitClicked && !guarantorData.city}
      >
        <InputLabel htmlFor="Gcity">City/Town</InputLabel>
        <OutlinedInput
          id="Gcity"
          name="city"
          value={guarantorData.city}
          onChange={inputChangeHandler("guarantor")}
          label="City/Town"
        />
        <FormHelperText>
          {submitClicked && !guarantorData.city && "City Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !guarantorData.state}
      >
        <InputLabel id="GState">State</InputLabel>
        <Select
          labelId="GState"
          id="GState"
          name="state"
          value={guarantorData.state}
          onChange={inputChangeHandler("guarantor")}
        >
          {state.map((stat, i) => {
            return (
              <MenuItem key={`${stat} - ${i}`} value={state[i]}>
                {stat}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {submitClicked && !guarantorData.state && "State Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <FormControl
        margin="dense"
        fullWidth
        error={submitClicked && !guarantorData.localGov}
      >
        <InputLabel id="2GLocal Govt.">Local Govt.</InputLabel>
        <Select
          labelId="GLocal Govt."
          id="GLocal Govt."
          name="localGov"
          value={guarantorData.localGov}
          onChange={inputChangeHandler("guarantor")}
        >
          {guarantorData.state &&
            NaijaStates.lgas(guarantorData.state).lgas.map((lgas, i) => {
              return (
                <MenuItem
                  key={`${lgas} - ${i}`}
                  value={NaijaStates.lgas(guarantorData.state).lgas[i]}
                >
                  {lgas}
                </MenuItem>
              );
            })}
        </Select>
        <FormHelperText>
          {submitClicked &&
            !guarantorData.localGov &&
            "Local Govt. Cannot Be Empty"}
        </FormHelperText>
      </FormControl>

      <Box sx={{ width: "100%" }}>
        <FormControl
          fullWidth
          sx={{ flexDirection: "row", alignItems: "center" }}
          margin="dense"
        >
          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            startIcon={<PhotoCamera />}
            onClick={() => guarantorAvatarInput.current.click()}
          >
            Choose a photo
          </Button>
          {guarantorData.avatarName && (
            <Typography
              sx={{ marginLeft: "0.5rem" }}
              color={submitClicked && !guarantorData.avatar && "error"}
            >
              {guarantorData.avatarName}
            </Typography>
          )}
          <input
            ref={guarantorAvatarInput}
            id="Gavatar"
            name="avatar"
            type="file"
            accept="image/*"
            onChange={inputChangeHandler("guarantor")}
            hidden
          />
        </FormControl>
        {submitClicked && !guarantorData.avatar && (
          <Box>
            <Typography color="error" variant="p">
              Profile Photo Is Required
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ marginTop: "1rem" }}
        endIcon={
          loading ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
              size={"1rem"}
            />
          ) : (
            <EastRounded />
          )
        }
      >
        Create
      </Button>
    </Box>
  );
};

export default AccountantForm;
