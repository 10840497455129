import AdminAccountInfo from "../AdminAccountInfo";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Add, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Link,
} from "@mui/material";
import WrapLayout from "../WrapLayout";
const AdminAccount = () => {
  const [dayQuery, setDayQuery] = useState("today");

  const handleDayChange = (event, newDayQuery) => {
    if (newDayQuery === null) {
      setDayQuery("today");
    } else {
      setDayQuery(newDayQuery);
    }
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "1rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Admin Account</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ width: "100%", marginBottom: "2rem" }}>
          <ToggleButtonGroup
            color="primary"
            value={dayQuery}
            exclusive
            onChange={handleDayChange}
            aria-label="Platform"
          >
            <ToggleButton sx={{ lineHeight: "1" }} value="dayBefore">
              Day Before
            </ToggleButton>
            <ToggleButton sx={{ lineHeight: "1" }} value="yesterday">
              Yesterday
            </ToggleButton>
            <ToggleButton sx={{ lineHeight: "1" }} value="today">
              Today
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ width: "100%" }}>
          <AdminAccountInfo date={dayQuery} />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default AdminAccount;
