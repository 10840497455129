import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useDeleteTerminalMutation } from "../store/terminal/terminalApiSlice";
import { useEffect, useState } from "react";

const TerminalCard = ({ terminal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [deleteTerminal, { isSuccess, isError, error }] =
    useDeleteTerminalMutation();

  useEffect(() => {
    if (isSuccess) {
      // !flash msg delete
    }
  }, [isSuccess]);

  const deleteHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    await deleteTerminal({
      id: terminal._id,
    });
  };

  const editHandler = () => {
    navigate(`/admin/terminal/${terminal._id}`);
  };

  return (
    <Card sx={{ mb: "1.5rem" }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Divider sx={{ width: "100%", mb: "1rem" }}>Terminal Details</Divider>
        <Typography variant="h5" component="div">
          {`SAMPAY${terminal.name.toUpperCase()}`}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {`Tag: ${terminal.location.toUpperCase()}`}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ justifyContent: "space-between", p: "0.5rem 1rem 1rem" }}
      >
        <Button variant="outlined" onClick={editHandler} endIcon={<Edit />}>
          Edit
        </Button>

        <Button
          variant="outlined"
          onClick={deleteHandler}
          endIcon={
            loading ? (
              <CircularProgress color="error" size={"1rem"} />
            ) : (
              <Delete />
            )
          }
          color="error"
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default TerminalCard;
