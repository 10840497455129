import dayjs from "dayjs";
import {
  useGetAdminAccountsQuery,
  useGetLegacyAdminAccountQuery,
} from "../store/adminAccount/adminAccountApiSlice";
import useAuth from "../hooks/useAuth";
import AdminAccountCard from "./AdminAccountCard";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const thousand = new Intl.NumberFormat("en-US");

const AdminAccountInfo = ({ date, legacy }) => {
  const { id } = useAuth();
  const [open, setOpen] = useState(false);
  const [accountData, setAccountData] = useState(null);

  const {
    data: account,
    isLoading: accountLoading,
    isSuccess: accountSuccess,
    isError: accountError,
  } = useGetAdminAccountsQuery(id, { skip: Boolean(legacy) });

  const {
    data: legacyAccount,
    isLoading: legacyLoading,
    isSuccess: legacySuccess,
    isError: legacyError,
  } = useGetLegacyAdminAccountQuery(legacy, { skip: Boolean(!legacy) });

  const isLoading = accountLoading || legacyLoading;
  const isSuccess = accountSuccess || legacySuccess;
  const isError = accountError || legacyError;

  useEffect(() => {
    if (account) {
      setAccountData(account);
    } else if (legacyAccount) {
      setAccountData(legacyAccount);
    }
  }, [account, legacyAccount]);

  const handleClick = () => {
    setOpen(!open);
  };

  if (isError) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Accounts </Typography>
      </Box>
    );
  }

  if (isLoading || !accountData) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess && accountData) {
    const { ids, entities } = accountData;
    const Accounts = [];

    ids.forEach((id) => {
      const responds = entities[id].data.filter((acc) => acc.id === date);
      const account = { accId: id, ...responds[0] };
      Accounts.push(account);
    });

    let adminCashOut = 0;
    let adminCashIn = 0;
    let adminShort = 0;
    let adminExpenses = 0;
    let adminTransPro = 0;
    let adminTransAcc = 0;
    let adminCharge = 0;
    let adminNet = 0;
    let fund = 0;
    let deduct = 0;
    let closeBal = 0;
    let count = 0;

    Accounts.forEach((account) => {
      count += account.no;
      fund += account.fund;
      deduct += account.deduct;
      closeBal += account.clBal;
      adminCashOut += account.cashOut;
      adminCashIn += account.cashIn;
      adminTransPro += account.transPro;
      adminTransAcc += account.transAcc;
      adminShort += account.short;
      adminCharge += account.net;
      adminNet += account.charge;
      if (account.shortage) {
        adminShort += account.shortage.amount;
      }
      if (account.expenses) {
        adminExpenses += account.expenses.amount;
      }
    });

    return (
      <Box>
        <Box
          component="main"
          sx={{ width: "80%", maxWidth: "30rem", margin: "0 auto" }}
        >
          <Card
            sx={{
              mb: "1.2rem",
              width: "100%",
              backgroundColor: "primary.main",
            }}
          >
            <CardContent>
              <Divider light={true} sx={{ mb: "0.5rem" }}>
                <Typography
                  variant="body2"
                  color="primary.contrastText"
                  sx={{ opacity: 0.5 }}
                >
                  {legacy ? dayjs(legacy).format("ll") : date}
                </Typography>
              </Divider>

              <Typography variant="h6" color="primary.contrastText">
                COUNT: {`${Accounts.length} - ${count}`}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                CASHOUT: {thousand.format(adminCashOut)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                CASHIN: {thousand.format(adminCashIn)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                TRANS PRO: {thousand.format(adminTransPro)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                TRANS ACC: {thousand.format(adminTransAcc)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                FUND: {thousand.format(fund)}
              </Typography>

              <Typography variant="h6" color="error">
                SHORTAGE: {thousand.format(adminShort)}
              </Typography>

              <Typography variant="h6" color="error">
                DEDUCT: {thousand.format(deduct)}
              </Typography>

              <Typography variant="h6">
                EXPENSES: {thousand.format(adminExpenses)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                NET: {thousand.format(adminNet)}
              </Typography>

              <Typography variant="h6" color="error">
                MP CHARGE: {thousand.format(adminCharge)}
              </Typography>

              <Typography variant="h6" color="primary.contrastText">
                WITH TOT: {thousand.format(closeBal)}
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box component="aside">
          <List
            sx={{
              width: "100%",
              maxWidth: "50rem",
              backgroundColor: "primary.container",
              borderRadius: "4px",
              margin: "2rem auto",
            }}
          >
            <ListItemButton onClick={() => handleClick()}>
              <ListItemText primary="SUMMARY" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ mt: "1rem" }}
            >
              <Box sx={{ width: "100%", maxWidth: "30rem", m: "0 auto" }}>
                {Accounts.map((account) => (
                  <AdminAccountCard key={account.accId} account={account} />
                ))}
              </Box>
            </Collapse>
          </List>
        </Box>
      </Box>
    );
  }
};

export default AdminAccountInfo;
