import CashierForm from "../CashierForm";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import { useGetCashiersQuery } from "../../store/cashier/cashierApiSlice";
import { useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const EditCashier = () => {
  const { id } = useParams();

  const {
    data: cashiers,
    isLoading,
    isSuccess,
    isError,
  } = useGetCashiersQuery();

  const cashier = cashiers?.entities[id];

  const cashierData = cashier
    ? {
        ...cashier,
        ...cashier.address,
        guarantor: "",
        address: "",
        avatar: "saved",
        avatarName: "profile_pic.jpeg",
      }
    : "";
  const GuarantorData = cashier
    ? {
        ...cashier.guarantor,
        ...cashier.guarantor.address,
        address: "",
        avatar: "saved",
        avatarName: "guarantor_pic.jpeg",
      }
    : "";

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/cashier">
              Cashiers
            </Link>
            <Typography color="text.primary">Update</Typography>2
          </Breadcrumbs>
        </Box>

        {isError && (
          <Box>
            <Typography variant="h5"> Something Went Wrong </Typography>
            <Typography> Unable To Fetch Accounts </Typography>
          </Box>
        )}

        {isLoading && (
          <Box sx={{ textAlign: "center", m: "2rem 0" }}>
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
              size={"3rem"}
            />
          </Box>
        )}

        {isSuccess && (
          <CashierForm
            edit={true}
            initialCashierData={cashierData}
            initialGuarantorData={GuarantorData}
            id={cashier.id}
          />
        )}
      </Box>
    </WrapLayout>
  );
};

export default EditCashier;
