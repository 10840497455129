import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const accountantAdapter = createEntityAdapter({});

const initialState = accountantAdapter.getInitialState();

export const accountantApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountants: builder.query({
      query: () => ({
        url: "/accountant",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAccountants = responseData.map((accountant) => {
          accountant.id = accountant._id;
          return accountant;
        });
        return accountantAdapter.setAll(initialState, loadedAccountants);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Accountant", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Accountant", id })),
          ];
        } else return [{ type: "Accountant", id: "LIST" }];
      },
    }),
    addNewAccountant: builder.mutation({
      query: (initialAccountant) => ({
        url: "/accountant",
        method: "POST",
        body: {
          ...initialAccountant,
        },
      }),
      invalidatesTags: [{ type: "Accountant", id: "LIST" }],
    }),
    updateAccountant: builder.mutation({
      query: (initialAccountant) => ({
        url: "/accountant",
        method: "PATCH",
        body: {
          ...initialAccountant,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Accountant", id: arg.id },
      ],
    }),
    deleteAccountant: builder.mutation({
      query: ({ id }) => ({
        url: "/accountant",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Accountant", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAccountantsQuery,
  useAddNewAccountantMutation,
  useUpdateAccountantMutation,
  useDeleteAccountantMutation,
} = accountantApiSlice;

// returns the query result object
export const selectAccountantsResult =
  accountantApiSlice.endpoints.getAccountants.select();

// creates memoized selector
const selectAccountantsData = createSelector(
  selectAccountantsResult,
  (AccountantsResult) => AccountantsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAccountants,
  selectById: selectAccountantById,
  selectIds: selectAccountantIds,
  // Pass in a selector that returns the Accountants slice of state
} = accountantAdapter.getSelectors(
  (state) => selectAccountantsData(state) ?? initialState
);
