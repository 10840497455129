import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTerminalRequestsQuery } from "../../store/terminalRequest/terminalRequestApiSlice";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { NavigateNext, West } from "@mui/icons-material";
import CopyToClip from "../CopyToClip";
import ImageDownload from "../ImageDownload";
import WrapLayout from "../WrapLayout";

const Request = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: requests,
    isLoading,
    isSuccess,
    isError,
  } = useGetTerminalRequestsQuery();

  const backHandler = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", margin: "2rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Requests </Typography>
      </div>
    );
  }

  if (isSuccess) {
    const request = requests.entities[id];
    return (
      <WrapLayout>
        <Box
          sx={{
            maxWidth: "90%",
            width: "90%",
            margin: "1.5rem auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid",
              paddingBottom: "1rem",
              marginBottom: "0.5rem",
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link underline="hover" color="inherit" href="/dashboard">
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/admin/requests">
                Requests
              </Link>
              <Typography color="text.primary">Request</Typography>
            </Breadcrumbs>
          </Box>
          <Box>
            <Box sx={{ margin: "2rem auto" }}>
              <Box>
                <Box sx={{ textAlign: "center", mb: { xs: "0", sm: "1rem" } }}>
                  <Typography variant="h5" component="h2">
                    Personal
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Title</Typography>
                    <CopyToClip value={request.personal.title} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>First Name</Typography>
                    <CopyToClip value={request.personal.firstName} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Last Name</Typography>
                    <CopyToClip value={request.personal.lastName} />
                  </Box>
                </Box>
                <Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Phone Number</Typography>
                    <CopyToClip value={request.personal.phone} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Email</Typography>
                    <CopyToClip value={request.personal.email} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Date Of Birth</Typography>
                    <CopyToClip
                      value={moment(request.personal.dob).format("LL")}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    mb: { xs: "0", sm: "1rem" },
                    mt: "1rem",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Residential Address
                  </Typography>
                </Box>
                {/* // !Home Add  */}
                <Box component="article" sx={{ width: "100%", mb: "1rem" }}>
                  <Typography>Street</Typography>
                  <CopyToClip value={request.placeOrigin.homeAdd.street} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>City</Typography>
                    <CopyToClip value={request.placeOrigin.homeAdd.city} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Local Govt.</Typography>
                    <CopyToClip value={request.placeOrigin.homeAdd.localGovt} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>State</Typography>
                    <CopyToClip value={request.placeOrigin.homeAdd.state} />
                  </Box>
                </Box>

                <Box
                  sx={{
                    textAlign: "center",
                    mb: { xs: "0", sm: "1rem" },
                    mt: "1rem",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Place Of Origin
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Local Govt.</Typography>
                    <CopyToClip value={request.placeOrigin.origin.localGovt} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>State</Typography>
                    <CopyToClip value={request.placeOrigin.origin.state} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Nationality</Typography>
                    <CopyToClip
                      value={request.placeOrigin.origin.nationality}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    mb: { xs: "0", sm: "1rem" },
                    mt: "1rem",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Identification
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>BVN</Typography>
                    <CopyToClip value={request.identification.bvn} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Id Card Type</Typography>
                    <CopyToClip value={request.identification.idType} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Id Number</Typography>
                    <CopyToClip value={request.identification.idNum} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    component="article"
                    sx={{ width: "100%", mt: "1rem", maxWidth: "32rem" }}
                  >
                    <Typography>Passport</Typography>
                    <ImageDownload
                      url={request.identification.passport}
                      name="Passport"
                    />
                  </Box>
                  <Box
                    component="article"
                    sx={{ width: "100%", mt: "1rem", maxWidth: "32rem" }}
                  >
                    <Typography>Id Photo</Typography>
                    <ImageDownload
                      url={request.identification.idPhoto}
                      name="Id-Photo"
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    mb: { xs: "0", sm: "1rem" },
                    mt: "1rem",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Business Info
                  </Typography>
                </Box>
                <Box component="article" sx={{ width: "100%" }}>
                  <Typography>Name</Typography>
                  <CopyToClip value={request.business.name} />
                </Box>
                <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                  <Typography>Street</Typography>
                  <CopyToClip value={request.business.location.street} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                    mt: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>City</Typography>
                    <CopyToClip value={request.business.location.city} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Local Govt.</Typography>
                    <CopyToClip value={request.business.location.localGovt} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>State</Typography>
                    <CopyToClip value={request.business.location.state} />
                  </Box>
                </Box>
                <Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Signature</Typography>
                    <ImageDownload
                      url={request.business.signature}
                      name="Signature"
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    mb: { xs: "0", sm: "1rem" },
                    mt: "1rem",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Next Of Kin
                  </Typography>
                </Box>
                <Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Full Name</Typography>
                    <CopyToClip value={request.kin.name} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Email</Typography>
                    <CopyToClip value={request.kin.email} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Phone Number</Typography>
                    <CopyToClip value={request.kin.number} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Relationship</Typography>
                    <CopyToClip value={request.kin.relationship} />
                  </Box>
                  <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                    <Typography>Date Of Birth</Typography>
                    <CopyToClip value={moment(request.kin.dob).format("LL")} />
                  </Box>
                </Box>
                <Box component="article" sx={{ width: "100%", mt: "1rem" }}>
                  <Typography>Street</Typography>
                  <CopyToClip value={request.kin.homeAdd.street} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                    mt: "1rem",
                  }}
                >
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>City</Typography>
                    <CopyToClip value={request.kin.homeAdd.city} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>Local Govt.</Typography>
                    <CopyToClip value={request.kin.homeAdd.localGovt} />
                  </Box>
                  <Box component="article" sx={{ width: "100%" }}>
                    <Typography>State</Typography>
                    <CopyToClip value={request.kin.homeAdd.state} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                onClick={backHandler}
                variant="contained"
                startIcon={<West />}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Box>
      </WrapLayout>
    );
  }
};

export default Request;
