import TerminalForm from "../TerminalForm";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import { useGetTerminalsQuery } from "../../store/terminal/terminalApiSlice";
import { useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const EditTerminal = () => {
  const { id } = useParams();

  const {
    data: terminals,
    isLoading,
    isSuccess,
    isError,
  } = useGetTerminalsQuery();

  const terminalData = terminals?.entities[id];

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/admin/terminal">
              Terminals
            </Link>
            <Typography color="text.primary">Update Terminal</Typography>2
          </Breadcrumbs>
        </Box>

        {isError && (
          <Box>
            <Typography variant="h5"> Something Went Wrong </Typography>
            <Typography> Unable To Fetch Accounts </Typography>
          </Box>
        )}

        {isLoading && (
          <Box sx={{ textAlign: "center", m: "2rem 0" }}>
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
              size={"3rem"}
            />
          </Box>
        )}

        {isSuccess && (
          <TerminalForm edit={true} initialTerminalData={terminalData} />
        )}
      </Box>
    </WrapLayout>
  );
};

export default EditTerminal;
