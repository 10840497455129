import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

const AccountantCard = ({ accountant }) => {
  return (
    <Card sx={{ mb: { xs: "1.2rem", sm: "1.7rem" } }}>
      <CardHeader
        avatar={
          <Avatar
            alt={accountant.firstName}
            src={accountant.avatarUrl}
            sx={{ width: 56, height: 56 }}
          />
        }
        // title={
        //   <Typography variant="h5">
        //     {accountant?.nickName.toUpperCase()}
        //   </Typography>
        // }
        title={
          <Typography variant="h5">
            {accountant?.firstName.toUpperCase()}
          </Typography>
        }
        subheader={accountant.role}
      />
      <CardContent>
        <Typography variant="h5">
          {/* ${accountant?.title.toUpperCase()} */}
          {`${accountant?.firstName.toUpperCase()} ${accountant?.lastName.toUpperCase()}`}
        </Typography>
        <Typography variant="h6">{`Permission: ${
          accountant.access ? "Granted" : "Restricted"
        }`}</Typography>
        <Typography variant="h6">{`${accountant?.phone}`}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" endIcon={<Edit />}>
          Edit
        </Button>

        <Button
          variant="outlined"
          color="error"
          // onClick={}
          endIcon={<Delete />}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountantCard;
