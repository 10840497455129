import { Box, Button } from "@mui/material";
import { useState } from "react";
import fileDownload from "js-file-download";

function ImageDownload({ url, name }) {
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [buttonText, setButtonText] = useState("Download");

  const enterHandler = () => {
    setShowDownloadButton(true);
  };

  const leaveHandler = () => {
    setButtonText("Download");
    setShowDownloadButton(false);
  };

  const downloadHandler = async () => {
    setButtonText("Downloading...");
    const imageName = `${name}.jpg`;
    const image = await fetch(url);
    const imageBlob = await image.blob();
    fileDownload(imageBlob, imageName);
    setButtonText("Downloaded");
  };

  const DownloadField = () => (
    <Box
      onMouseEnter={enterHandler}
      onMouseLeave={leaveHandler}
      sx={{ position: "relative", width: "100%", maxWidth: "28rem" }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "32rem",
          height: "12rem",
          backgroundImage: `url(${url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: (theme) => theme.palette.primary.inverse,
          borderRadius: "0.2rem",
        }}
      >
        {showDownloadButton && (
          <Button
            onClick={downloadHandler}
            variant="outlined"
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: (theme) => theme.palette.primary.container,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
              },
            }}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );

  return <DownloadField />;
}

export default ImageDownload;
