import { Delete } from "@mui/icons-material";
import { useDeleteSuggestionMutation } from "../store/suggestion/suggestionApiSlice";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useState } from "react";

const SuggestionCard = ({ suggestion }) => {
  const [loading, setLoading] = useState(false);
  const [deleteSuggestion] = useDeleteSuggestionMutation();
  const deleteHandler = async () => {
    setLoading(true);
    await deleteSuggestion(suggestion.id);
  };
  return (
    <Box sx={{ maxWidth: "80" }}>
      <Card sx={{ mb: "1.2rem", width: "100%", pb: "0" }}>
        <CardContent sx={{ pb: "0" }}>
          <Typography variant="h6" component="h5">
            {suggestion.title}
          </Typography>
          <Divider></Divider>
          <Typography variant="h5" component="p" mb="0.5rem">
            {suggestion.suggestion}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "0.5rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            sx={{ ml: "0.5rem" }}
            startIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <Delete />
              )
            }
            onClick={deleteHandler}
          >
            Delete
          </Button>
          <Typography
            variant="caption"
            component="small"
            sx={{ fontWeight: "bold" }}
          >
            {`${suggestion.accountant?.nickName?.toUpperCase()}`}
          </Typography>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SuggestionCard;
