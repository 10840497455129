import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const terminalRequestAdapter = createEntityAdapter({});

const initialState = terminalRequestAdapter.getInitialState();

export const terminalRequestApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTerminalRequests: builder.query({
      query: () => ({
        url: "/terminal-request",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedTerminalRequest = responseData.map((terminalRequest) => {
          terminalRequest.id = terminalRequest._id;
          return terminalRequest;
        });
        return terminalRequestAdapter.setAll(
          initialState,
          loadedTerminalRequest
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "TerminalRequest", id: "LIST" },
            ...result.ids.map((id) => ({ type: "TerminalRequest", id })),
          ];
        } else return [{ type: "TerminalRequest", id: "LIST" }];
      },
    }),
    addNewTerminalRequest: builder.mutation({
      query: (initialTerminalRequest) => ({
        url: "/terminal-request",
        method: "POST",
        body: {
          ...initialTerminalRequest,
        },
      }),
      invalidatesTags: [{ type: "TerminalRequest", id: "LIST" }],
    }),
    updateTerminalRequest: builder.mutation({
      query: (initialTerminalRequest) => ({
        url: "/terminal-request",
        method: "PATCH",
        body: {
          ...initialTerminalRequest,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TerminalRequest", id: arg.id },
      ],
    }),
    deleteTerminalRequest: builder.mutation({
      query: (id) => ({
        url: `/terminal-request`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TerminalRequest", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetTerminalRequestsQuery,
  useAddNewTerminalRequestMutation,
  useUpdateTerminalRequestMutation,
  useDeleteTerminalRequestMutation,
} = terminalRequestApiSlice;

// returns the query result object
export const selectTerminalRequestResult =
  terminalRequestApiSlice.endpoints.getTerminalRequests.select();

// creates memoized selector
const selectTerminalRequestData = createSelector(
  selectTerminalRequestResult,
  (TerminalRequestResult) => TerminalRequestResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTerminalRequest,
  selectById: selectTerminalRequestById,
  selectIds: selectTerminalRequestIds,
  // Pass in a selector that returns the TerminalRequest slice of state
} = terminalRequestAdapter.getSelectors(
  (state) => selectTerminalRequestData(state) ?? initialState
);
