import {
  Typography,
  Link,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Login, Logout } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import { useSendLogoutMutation } from "../store/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SideNav = ({ toggleHandler }) => {
  const navigate = useNavigate();
  const { email } = useAuth();
  const [sendLogout, { isSuccess }] = useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  //   const router = useRouter();
  const logOutHandler = () => {
    toggleHandler();
    sendLogout();
  };

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <Link
          underline="hover"
          color="inherit"
          href="/"
          onClick={() => toggleHandler()}
        >
          <ListItemButton>
            <ListItemText
              primary={
                <Typography variant="h6" component="h6">
                  Home
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/#about"
          onClick={() => toggleHandler()}
        >
          <ListItemButton>
            <ListItemText
              primary={
                <Typography variant="h6" component="h6">
                  About Us
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/#contact"
          onClick={() => toggleHandler()}
        >
          <ListItemButton>
            <ListItemText
              primary={
                <Typography variant="h6" component="h6">
                  Contact Us
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
        {email && (
          <Link
            underline="hover"
            color="inherit"
            href="/dashboard"
            onClick={() => toggleHandler()}
          >
            <ListItemButton>
              <ListItemText
                primary={
                  <Typography variant="h6" component="h6">
                    Dashboard
                  </Typography>
                }
              />
            </ListItemButton>
          </Link>
        )}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          {email && (
            <ListItemButton onClick={logOutHandler}>
              <ListItemIcon>
                <Logout color="error" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" component="h6">
                    Log Out
                  </Typography>
                }
                sx={{ color: (theme) => theme.palette.error.main }}
              />
            </ListItemButton>
          )}
          {!email && (
            <Link
              underline="none"
              color="inherit"
              href="/login"
              sx={{ width: "100%" }}
              onClick={() => toggleHandler()}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Login color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" component="h6">
                      Log In
                    </Typography>
                  }
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </ListItemButton>
            </Link>
          )}
        </ListItem>
      </List>
    </div>
  );
};
export default SideNav;
