import AccountantCard from "./AccountantCard";
import { useGetAccountantsQuery } from "../store/accountant/accountantApiSlice";
import { Box, CircularProgress, Typography } from "@mui/material";

const AccountantInfo = () => {
  const {
    data: accountants,
    isLoading,
    isSuccess,
    isError,
  } = useGetAccountantsQuery();

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Accountants </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = accountants;
    if (!ids.length) {
      return (
        <Box>
          <Typography variant="h4"> No Accountant Yet </Typography>
          <Typography variant="p" component="p">
            Try adding new accountant
          </Typography>
        </Box>
      );
    }

    const reversedIds = ids.slice().reverse();
    return reversedIds.map((accountantId) => (
      <AccountantCard key={accountantId} accountant={entities[accountantId]} />
    ));
  }
};

export default AccountantInfo;
