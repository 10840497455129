import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import { useAddNewAnnouncementMutation } from "../store/announcement/announcementApiSlice";

const NewAnnouncement = ({ initialData }) => {
  const navigate = useNavigate();
  const errorMsgRef = useRef();

  const [announcementData, setAnnouncementData] = useState("");

  useEffect(() => {
    setAnnouncementData(initialData);
  }, [initialData]);

  const [addNewAnnouncement, { isSuccess, isError, error }] =
    useAddNewAnnouncementMutation();

  useEffect(() => {
    if (isSuccess) {
      setAnnouncementData(initialData);
      navigate("/announcement");
    }
    if (isError) {
      setLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [isSuccess, navigate, initialData, isError, error]);

  const canSave = true;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAnnouncementData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const userSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (canSave) {
      await addNewAnnouncement({
        ...announcementData,
      });
    }
  };

  if (!announcementData) {
    return (
      <Box sx={{ textAlign: "center", margin: "2rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={userSubmitHandler}
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "1rem auto 1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h4" component="h2" sx={{ mb: "1rem" }}>
          Create Announcement
        </Typography>
      </Box>

      <Box ref={errorMsgRef} sx={{ width: "100%" }}>
        {errorMsg && (
          <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
            {errorMsg}
          </Alert>
        )}
      </Box>

      <FormControl fullWidth margin="dense">
        <InputLabel htmlFor="title">Title</InputLabel>
        <OutlinedInput
          id="title"
          name="head"
          value={announcementData.head}
          onChange={inputChangeHandler}
          label="Title"
        />
      </FormControl>

      <FormControl fullWidth margin="dense">
        <InputLabel htmlFor="announcement">Announcement</InputLabel>
        <OutlinedInput
          id="announcement"
          name="body"
          multiline
          rows={4}
          value={announcementData.body}
          onChange={inputChangeHandler}
          label="Announcement"
        />
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ marginTop: "1rem" }}
        endIcon={
          loading ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
              size={"1rem"}
            />
          ) : (
            <EastRounded />
          )
        }
      >
        Create
      </Button>
    </Box>
  );
};

export default NewAnnouncement;
