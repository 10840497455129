import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const cashierAdapter = createEntityAdapter({});

const initialState = cashierAdapter.getInitialState();

export const cashierApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCashiers: builder.query({
      query: () => ({
        url: "/cashier",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedCashiers = responseData.map((cashier) => {
          cashier.id = cashier._id;
          return cashier;
        });
        return cashierAdapter.setAll(initialState, loadedCashiers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Cashier", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Cashier", id })),
          ];
        } else return [{ type: "Cashier", id: "LIST" }];
      },
    }),
    addNewCashier: builder.mutation({
      query: (initialCashier) => ({
        url: "/cashier",
        method: "POST",
        body: {
          ...initialCashier,
        },
      }),
      invalidatesTags: [{ type: "Cashier", id: "LIST" }],
    }),
    updateCashier: builder.mutation({
      query: (initialCashier) => ({
        url: "/cashier",
        method: "PATCH",
        body: {
          ...initialCashier,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Cashier", id: arg.id },
      ],
    }),
    deleteCashier: builder.mutation({
      query: (id) => ({
        url: "/cashier",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Cashier", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCashiersQuery,
  useAddNewCashierMutation,
  useUpdateCashierMutation,
  useDeleteCashierMutation,
} = cashierApiSlice;

// returns the query result object
export const selectCashiersResult =
  cashierApiSlice.endpoints.getCashiers.select();

// creates memoized selector
const selectCashiersData = createSelector(
  selectCashiersResult,
  (CashiersResult) => CashiersResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCashiers,
  selectById: selectCashierById,
  selectIds: selectCashierIds,
  // Pass in a selector that returns the Cashiers slice of state
} = cashierAdapter.getSelectors(
  (state) => selectCashiersData(state) ?? initialState
);
