import { Delete } from "@mui/icons-material";
import { useDeleteMessageMutation } from "../store/message/messageApiSlice";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useState } from "react";

const MessageCard = ({ message }) => {
  console.log(
    "🚀 ~ file: MessageCard.jsx:16 ~ MessageCard ~ message:",
    message
  );
  const [loading, setLoading] = useState(false);
  const [deleteMessage] = useDeleteMessageMutation();
  const deleteHandler = async () => {
    setLoading(true);
    await deleteMessage(message.id);
  };
  return (
    <Box sx={{ maxWidth: "90" }}>
      <Card sx={{ mb: "1.2rem", width: "100%", pb: "0" }}>
        <CardContent sx={{ pb: "0" }}>
          <Typography variant="p" component="h6" sx={{ fontWeight: "bold" }}>
            Contact
          </Typography>
          <Typography variant="p" component="h5">
            Name: {message.name?.toUpperCase()}
          </Typography>
          <Typography variant="p" component="h5">
            Email: {message.email}
          </Typography>
          <Typography variant="p" component="h5">
            Phone: {message.phone}
          </Typography>
          <Divider sx={{ m: "0.5rem 0" }}></Divider>
          <Typography variant="p" component="h6" sx={{ fontWeight: "bold" }}>
            Message
          </Typography>
          <Typography variant="h5" component="p" m="0.5rem 0 1rem">
            {message.message}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "0.5rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            sx={{ ml: "0.5rem" }}
            startIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <Delete />
              )
            }
            onClick={deleteHandler}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default MessageCard;
