import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAccountQuery,
  useUpdateAccountShortMutation,
} from "../../store/account/accountApiSlice";
import useAuth from "../../hooks/useAuth";
import NumberFormat from "../NumberFormat";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Divider,
  Card,
  CardContent,
  CardActions,
  Alert,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Edit, Done, Add, NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";
const thousand = new Intl.NumberFormat("en-US");

const Account = () => {
  const { id: accId } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const [short, setShort] = useState({
    amount: "",
    head: "",
    description: "",
  });
  const [toggleShort, setToggleShort] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const {
    data: acct,
    isLoading,
    isSuccess: accountSuccess,
    isError: accountError,
  } = useGetAccountQuery(id);

  const account = acct?.entities[id];

  // const { account } = useGetAccountsQuery(accId, {
  //   selectFromResult: ({ data }) => ({
  //     account: data?.entities[id],
  //   }),
  // });

  const [updateAccount, { isSuccess }] = useUpdateAccountShortMutation();

  useEffect(() => {
    if (account?.shortage) {
      setShort({
        shtId: account.shortage._id,
        amount: account.shortage.amount.toString(),
        head: account.shortage.head,
        description: account.shortage.description,
      });
      setToggleShort(true);
    }
  }, [account?.shortage]);

  useEffect(() => {
    if (isSuccess) {
      setShort({
        amount: "",
        head: "",
        description: "",
      });
      navigate("/account");
    }
  }, [isSuccess, navigate]);

  const shortSubmitHandler = async (event) => {
    setSubmitLoading(true);
    event.preventDefault();
    await updateAccount({
      short,
      accId,
      id: account._id,
      cashier: account.cashier.id,
      terminal: account.terminal.id,
      oldShort: account.shortage,
    });
  };

  const editHandler = () => {
    setEditLoading(true);
    navigate(`/account/edit/${id}`);
  };

  const toggleShortHandler = () => {
    setToggleShort(!toggleShort);
  };

  const shortChangeHandler = (event) => {
    const name = event.target.name;
    setShort((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", margin: "2rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (accountError) {
    return (
      <Box sx={{ textAlign: "center", margin: "2rem" }}>
        <div>
          <Typography variant="h5"> Something Went Wrong </Typography>
          <Typography> Unable To Fetch Account </Typography>
        </div>
      </Box>
    );
  }
  if (accountSuccess && account) {
    return (
      <WrapLayout>
        <Box
          sx={{
            maxWidth: "90%",
            width: "90%",
            margin: "1.5rem auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid",
              paddingBottom: "1rem",
              marginBottom: "0.5rem",
              borderColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link underline="hover" color="inherit" href="/dashboard">
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/account">
                Accounts
              </Link>
              <Typography color="text.primary">Account</Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ width: "90%", margin: "2rem auto" }}>
            {errorMsg && (
              <Alert severity="error" sx={{ width: "100%", mb: ".5rem" }}>
                {errorMsg}
              </Alert>
            )}

            <Card sx={{ mb: "1.2rem", width: "100%" }}>
              <CardContent>
                <Divider>
                  <Typography variant="body2" color="primary">
                    {account.terminal.location.toUpperCase()}
                  </Typography>
                </Divider>
                <Typography variant="h6">
                  NAME: {account.cashier.name.toUpperCase()}
                </Typography>
                <Typography variant="h6">
                  CASHOUT: {thousand.format(account.cashOut)}
                </Typography>
                <Typography variant="h6" color="error">
                  TERMINAL: {thousand.format(account.withdraw)}
                </Typography>
                <Typography variant="h6" color="error">
                  TRANS PRO: {thousand.format(account.transPro)}
                </Typography>
                <Typography variant="h6" color="error">
                  TRANS ACC: {thousand.format(account.transAcc)}
                </Typography>
                <Typography variant="h6" color="error">
                  POS TRANS: {thousand.format(account.posTrans)}
                </Typography>
                <Typography variant="h6">
                  DEPOSIT: {thousand.format(account.deposit)}
                </Typography>
                <Typography variant="h6" color="error">
                  CASH IN: {thousand.format(account.cashIn)}
                </Typography>
                <Typography variant="h6">
                  CL BAL: {thousand.format(account.closeBal)}
                </Typography>

                {!toggleShort && (
                  <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={toggleShortHandler}
                  >
                    Add Shortage
                  </Button>
                )}

                {toggleShort && (
                  <>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="h6" component="span">
                        SHORTAGE:
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={toggleShortHandler}
                      >
                        hide
                      </Button>
                    </Box>
                    <Box
                      component="form"
                      sx={{ margin: "0.5rem 0 0 1rem" }}
                      noValidate
                    >
                      <Box sx={{ mb: "0.5rem" }}>
                        <TextField
                          label="Amount"
                          value={short.amount}
                          name="amount"
                          onChange={shortChangeHandler}
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormat,
                          }}
                          variant="outlined"
                        />
                      </Box>

                      <Box sx={{ mb: "0.5rem" }}>
                        <TextField
                          value={short.head}
                          variant="outlined"
                          name="head"
                          size="small"
                          fullWidth
                          label="Shortage Head"
                          onChange={shortChangeHandler}
                        />
                      </Box>

                      <Box sx={{ mb: "0.5rem" }}>
                        <TextField
                          value={short.description}
                          variant="outlined"
                          size="small"
                          name="description"
                          fullWidth
                          label="Description"
                          multiline
                          rows={3}
                          onChange={shortChangeHandler}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={editHandler}
                  endIcon={
                    editLoading ? (
                      <CircularProgress
                        sx={{
                          color: (theme) => theme.palette.primary.contrastText,
                        }}
                        size={"1rem"}
                      />
                    ) : (
                      <Edit />
                    )
                  }
                >
                  Edit
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  onClick={shortSubmitHandler}
                  endIcon={
                    submitLoading ? (
                      <CircularProgress
                        sx={{
                          color: (theme) => theme.palette.primary.contrastText,
                        }}
                        size={"1rem"}
                      />
                    ) : (
                      <Done />
                    )
                  }
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </WrapLayout>
    );
  }
};

export default Account;
