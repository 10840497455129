import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

const NumericInputFormat = forwardRef(function NumberFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
    />
  );
});

export default NumericInputFormat;
