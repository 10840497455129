import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Badge,
  Link,
  Avatar,
  IconButton,
  InputAdornment,
  Breadcrumbs,
} from "@mui/material";
import {
  AddAPhoto,
  EastRounded,
  NavigateNext,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import {
  useGetAccountantsQuery,
  useUpdateAccountantMutation,
} from "../../store/accountant/accountantApiSlice";
import PhoneFormat from "../PhoneFormat";
import WrapLayout from "../WrapLayout";

const Profile = () => {
  const navigate = useNavigate();
  const { id } = useAuth();
  const errorMsgRef = useRef();
  const [showPassword, setShowPassword] = useState("");
  const [avatarChange, setAvatarChange] = useState(false);

  const cashierAvatarInput = useRef();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [accountantData, setAccountantData] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const {
    data: accountants,
    isLoading: accLoading,
    isSuccess: accSuccess,
    isError: accIsError,
    error: accError,
  } = useGetAccountantsQuery();

  const [updateAccountant, { isSuccess, isError, error }] =
    useUpdateAccountantMutation();

  useEffect(() => {
    if (isSuccess) {
      //! Flash Message: Is sucessful;
      // navigate("/cashier");
      setLoading(false);
    }
    if (isError) {
      setLoading(false);
      setErrorMsg(error.data.message);
      window.scrollTo({
        top: errorMsgRef.current?.offsetTop - 90,
        behavior: "smooth",
        nearest: "block",
      });
    }
  }, [isSuccess, navigate, isError, error]);

  useEffect(() => {
    if (accSuccess) {
      const { entities } = accountants;

      setAccountantData({ oldPassword: "", newPassword: "", ...entities[id] });
    }
  }, [accSuccess, accountants, id]);

  const clearAvatar = () => {
    setAccountantData((prevState) => ({
      ...prevState,
      avatarUrl: "",
    }));
    setAvatarChange(true);
  };

  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "avatar") {
      const image = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setAccountantData((prevState) => ({
          ...prevState,
          avatarName: image.name,
          avatarUrl: reader.result,
        }));
        setAvatarChange(true);
      };
    } else {
      setAccountantData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validatePhone = (number) => {
    if (String(number).length === 11) {
      return true;
    } else {
      return false;
    }
  };

  const passwordVisibiityHandler = () => {
    setShowPassword(!showPassword);
  };

  const inputPreventDefault = (event) => {
    event.preventDefault();
  };

  const userSubmitHandler = async (event) => {
    setSubmitClicked(true);
    event.preventDefault();

    if (canSave) {
      setLoading(true);

      await updateAccountant({
        ...accountantData,
        profile: true,
        avatarChange,
      });
    }
  };

  const imgBtn = (
    <Box>
      <Button
        onClick={() => cashierAvatarInput.current.click()}
        sx={{ padding: 0 }}
      >
        <AddAPhoto
          sx={{
            fontSize: "2rem",
            color: (theme) => theme.palette.common.outline,
          }}
        />
      </Button>
      <input
        ref={cashierAvatarInput}
        id="avatar"
        name="avatar"
        type="file"
        accept="image/*"
        onChange={inputChangeHandler}
        hidden
      />
    </Box>
  );

  if (accLoading || !accountantData) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (accError) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Get Account Form </Typography>
      </Box>
    );
  }

  const passwordCheck = () => {
    if (accountantData.oldPassword && accountantData.newPassword) {
      return true;
    } else if (!accountantData.oldPassword && !accountantData.newPassword) {
      return true;
    } else if (!accountantData.oldPassword && accountantData.newPassword) {
      return true;
    } else {
      return false;
    }
  };

  const canSave =
    !!accountantData.title &&
    !!accountantData.firstName &&
    !!accountantData.lastName &&
    !!accountantData.nickName &&
    !!accountantData.phone &&
    passwordCheck();

  const phoneErrMsgCashier =
    (!accountantData.phone && "Phone Number Cannot Be Empty") ||
    (!validatePhone(accountantData.phone) && "Pls enter a valid Phone Number");

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "2rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Profile</Typography>
          </Breadcrumbs>
        </Box>
        <Box
          component="form"
          onSubmit={userSubmitHandler}
          sx={{
            maxWidth: "90%",
            width: "100%",
            margin: "1rem auto 1.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography variant="h4" component="h2" sx={{ mb: "1rem" }}>
              Update Profile
            </Typography>
          </Box>

          <Box ref={errorMsgRef} sx={{ width: "100%" }}>
            {errorMsg && (
              <Alert severity="error" sx={{ width: "100%", m: "1rem" }}>
                {errorMsg}
              </Alert>
            )}
          </Box>

          <Box mb="1rem">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={imgBtn}
            >
              <Avatar
                alt={accountantData.nickName}
                src={accountantData.avatarUrl}
                sx={{ width: "8rem", height: "8rem" }}
              />
            </Badge>
          </Box>
          <Box>
            <Button variant="contained" onClick={clearAvatar}>
              Clear Pic
            </Button>
          </Box>

          <FormControl
            margin="dense"
            fullWidth
            error={submitClicked && !accountantData.title}
          >
            <InputLabel id="Title">Title</InputLabel>
            <Select
              labelId="Title"
              id="Title"
              name="title"
              value={accountantData.title}
              onChange={inputChangeHandler}
            >
              <MenuItem value={"mr"}>Mr</MenuItem>
              <MenuItem value={"mrs"}>Mrs</MenuItem>
              <MenuItem value={"miss"}>Miss</MenuItem>
              <MenuItem value={"mister"}>Mister</MenuItem>
            </Select>
            <FormHelperText>
              {submitClicked &&
                !accountantData.title &&
                "Title Cannot Be Empty"}
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            margin="dense"
            error={submitClicked && !accountantData.firstName}
          >
            <InputLabel htmlFor="firstName">First Name</InputLabel>
            <OutlinedInput
              id="firstName"
              name="firstName"
              value={accountantData.firstName}
              onChange={inputChangeHandler}
              label="First Name"
            />
            <FormHelperText>
              {submitClicked &&
                !accountantData.firstName &&
                "First Name Cannot Be Empty"}
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            margin="dense"
            error={submitClicked && !accountantData.lastName}
          >
            <InputLabel htmlFor="lastName">Last Name(Surname)</InputLabel>
            <OutlinedInput
              id="lastName"
              name="lastName"
              value={accountantData.lastName}
              onChange={inputChangeHandler}
              label="Last Name(Surname)"
            />
            <FormHelperText>
              {submitClicked &&
                !accountantData.lastName &&
                "Last Name Cannot Be Empty"}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="email">Email</InputLabel>
            <OutlinedInput
              id="email"
              name="email"
              value={accountantData.email}
              label="email"
              disabled
            />
          </FormControl>

          <FormControl
            fullWidth
            margin="dense"
            error={submitClicked && !accountantData.nickName}
          >
            <InputLabel htmlFor="nickName">Nickname</InputLabel>
            <OutlinedInput
              id="nickName"
              name="nickName"
              value={accountantData.nickName}
              onChange={inputChangeHandler}
              label="NickName"
            />
            <FormHelperText>
              {submitClicked &&
                !accountantData.nickName &&
                "Last Name Cannot Be Empty"}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="role">Role</InputLabel>
            <OutlinedInput
              id="role"
              name="role"
              value={accountantData.role}
              label="role"
              disabled
            />
          </FormControl>

          <TextField
            id="Phone Number"
            label="Phone Number"
            name="phone"
            value={accountantData.phone}
            onChange={inputChangeHandler}
            margin="dense"
            InputProps={{
              inputComponent: PhoneFormat,
            }}
            fullWidth
            error={submitClicked && !!phoneErrMsgCashier}
            helperText={submitClicked && phoneErrMsgCashier}
          />

          <Box sx={{ width: "100%" }}>
            <Typography sx={{ paddingTop: "0.2rem", opacity: "0.5" }}>
              Change Password
            </Typography>
          </Box>

          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="password">Old Password</InputLabel>

            <OutlinedInput
              id="password"
              name="oldPassword"
              type={showPassword ? "text" : "password"}
              value={accountantData.oldPassword}
              onChange={inputChangeHandler}
              label="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={passwordVisibiityHandler}
                    onMouseDown={inputPreventDefault}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            margin="dense"
            error={
              submitClicked &&
              !!accountantData.oldPassword &&
              !accountantData.newPassword
            }
          >
            <InputLabel htmlFor="newPassword">New Password</InputLabel>

            <OutlinedInput
              id="newPassword"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              value={accountantData.newPassword}
              onChange={inputChangeHandler}
              label="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={passwordVisibiityHandler}
                    onMouseDown={inputPreventDefault}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              {submitClicked &&
                accountantData.oldPassword &&
                !accountantData.newPassword &&
                "New Password Cannot Be Empty"}
            </FormHelperText>
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{ marginTop: "1rem" }}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <EastRounded />
              )
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Profile;
