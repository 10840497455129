import * as colors from '../styles/_themes.module.scss';
// import * as types from '../styles/_typography.module.scss';
import LinkBehavior from './LinkBehavior';

const colorTokens = colors.default;
// const typeTokens = types.default;
const Themes = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          common: {
            shadow: colorTokens.lightShadow,
            outline: colorTokens.lightOutline,
          },
          primary: {
            main: colorTokens.lightPrimary,
            contrastText: colorTokens.lightOnPrimary,
            inverse: colorTokens.lightInversePrimary,
            container: colorTokens.lightPrimaryContainer,
            onContainer: colorTokens.lightOnPrimaryContainer,
          },
          secondary: {
            main: colorTokens.lightSecondary,
            contrastText: colorTokens.lightOnSecondary,
            container: colorTokens.lightSecondaryContainer,
            onContainer: colorTokens.lightOnSecondaryContainer,
          },
          tertiary: {
            main: colorTokens.lightTertiary,
            contrastText: colorTokens.lightOnTertiary,
            container: colorTokens.lightTertiaryContainer,
            onContainer: colorTokens.lightOnTertiaryContainer,
          },
          error: {
            main: colorTokens.lightError,
            contrastText: colorTokens.lightOnError,
            container: colorTokens.lightErrorContainer,
            onContainer: colorTokens.lightOnErrorContainer,
          },
          warning: {
            main: colorTokens.lightWarning,
            contrastText: colorTokens.lightOnWarning,
            container: colorTokens.lightWarningContainer,
            onContainer: colorTokens.lightOnWarningContainer,
          },
          surface: {
            main: colorTokens.lightSurface,
            contrastText: colorTokens.lightOnSurface,
            inverse: colorTokens.lightInverseSurface,
            onInverse: colorTokens.lightOnInverseSurface,
            variant: colorTokens.lightSurfaceVariant,
            onVariant: colorTokens.lightOnSurfaceVariant,
          },
          background: {
            paper: colorTokens.lightBackground,
            default: colorTokens.lightBackground,
            onBackground: colorTokens.lightOnBackground,
          },
        }
      : {
          // palette values for dark mode
          common: {
            shadow: colorTokens.darkShadow,
            outline: colorTokens.darkOutline,
          },
          primary: {
            main: colorTokens.darkPrimary,
            contrastText: colorTokens.darkOnPrimary,
            inverse: colorTokens.darkInversePrimary,
            container: colorTokens.darkPrimaryContainer,
            onContainer: colorTokens.darkOnPrimaryContainer,
          },
          secondary: {
            main: colorTokens.darkSecondary,
            contrastText: colorTokens.darkOnSecondary,
            container: colorTokens.darkSecondaryContainer,
            onContainer: colorTokens.darkOnSecondaryContainer,
          },
          tertiary: {
            main: colorTokens.darkTertiary,
            contrastText: colorTokens.darkOnTertiary,
            container: colorTokens.darkTertiaryContainer,
            onContainer: colorTokens.darkOnTertiaryContainer,
          },
          error: {
            main: colorTokens.darkError,
            contrastText: colorTokens.darkOnError,
            container: colorTokens.darkErrorContainer,
            onContainer: colorTokens.darkOnErrorContainer,
          },
          warning: {
            main: colorTokens.darkWarning,
            contrastText: colorTokens.darkOnWarning,
            container: colorTokens.darkWarningContainer,
            onContainer: colorTokens.darkOnWarningContainer,
          },
          surface: {
            main: colorTokens.darkSurface,
            contrastText: colorTokens.darkOnSurface,
            inverse: colorTokens.darkInverseSurface,
            onInverse: colorTokens.darkOnInverseSurface,
            variant: colorTokens.darkSurfaceVariant,
            onVariant: colorTokens.darkOnSurfaceVariant,
          },
          background: {
            paper: colorTokens.darkBackground,
            default: colorTokens.darkBackground,
            onBackground: colorTokens.darkOnBackground,
          },
        }),
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  // typography: {
  //   label_small: {
  //     fontWeight: typeTokens.labelSmallFontWeight,
  //     lineHeight: typeTokens.labelSmallLineHeight,
  //     fontSize: typeTokens.labelSmallFontSize,
  //     letterSpacing: typeTokens.labelSmallLetterSpacing,
  //   },
  //   label_medium: {
  //     fontWeight: typeTokens.labelMediumFontWeight,
  //     lineHeight: typeTokens.labelMediumLineHeight,
  //     fontSize: typeTokens.labelMediumFontSize,
  //     letterSpacing: typeTokens.labelMediumLetterSpacing,
  //   },
  //   label_large: {
  //     fontWeight: typeTokens.labelLargeFontWeight,
  //     lineHeight: typeTokens.labelLargeLineHeight,
  //     fontSize: typeTokens.labelLargeFontSize,
  //     letterSpacing: typeTokens.labelLargeLetterSpacing,
  //   },
  //   body_small: {
  //     fontWeight: typeTokens.bodySmallFontWeight,
  //     lineHeight: typeTokens.bodySmallLineHeight,
  //     fontSize: typeTokens.bodySmallFontSize,
  //     letterSpacing: typeTokens.bodySmallLetterSpacing,
  //   },
  //   body_medium: {
  //     fontWeight: typeTokens.bodyMediumFontWeight,
  //     lineHeight: typeTokens.bodyMediumLineHeight,
  //     fontSize: typeTokens.bodyMediumFontSize,
  //     letterSpacing: typeTokens.bodyMediumLetterSpacing,
  //   },
  //   body_large: {
  //     fontWeight: typeTokens.bodyLargeFontWeight,
  //     lineHeight: typeTokens.bodyLargeLineHeight,
  //     fontSize: typeTokens.bodyLargeFontSize,
  //     letterSpacing: typeTokens.bodyLargeLetterSpacing,
  //   },
  //   headline_small: {
  //     fontWeight: typeTokens.headlineSmallFontWeight,
  //     lineHeight: typeTokens.headlineSmallLineHeight,
  //     fontSize: typeTokens.headlineSmallFontSize,
  //     letterSpacing: typeTokens.headlineSmallLetterSpacing,
  //   },
  //   headline_medium: {
  //     fontWeight: typeTokens.headlineMediumFontWeight,
  //     lineHeight: typeTokens.headlineMediumLineHeight,
  //     fontSize: typeTokens.headlineMediumFontSize,
  //     letterSpacing: typeTokens.headlineMediumLetterSpacing,
  //   },
  //   headline_large: {
  //     fontWeight: typeTokens.headlineLargeFontWeight,
  //     lineHeight: typeTokens.headlineLargeLineHeight,
  //     fontSize: typeTokens.headlineLargeFontSize,
  //     letterSpacing: typeTokens.headlineLargeLetterSpacing,
  //   },
  //   display_small: {
  //     fontWeight: typeTokens.displaySmallFontWeight,
  //     lineHeight: typeTokens.displaySmallLineHeight,
  //     fontSize: typeTokens.displaySmallFontSize,
  //     letterSpacing: typeTokens.displaySmallLetterSpacing,
  //   },
  //   display_medium: {
  //     fontWeight: typeTokens.displayMediumFontWeight,
  //     lineHeight: typeTokens.displayMediumLineHeight,
  //     fontSize: typeTokens.displayMediumFontSize,
  //     letterSpacing: typeTokens.displayMediumLetterSpacing,
  //   },
  //   display_large: {
  //     fontWeight: typeTokens.displaylargeFontWeight,
  //     lineHeight: typeTokens.displaylargeLineHeight,
  //     fontSize: typeTokens.displaylargeFontSize,
  //     letterSpacing: typeTokens.displaylargeLetterSpacing,
  //   },
  //   title_small: {
  //     fontWeight: typeTokens.titleSmallFontWeight,
  //     lineHeight: typeTokens.titleSmallLineHeight,
  //     fontSize: typeTokens.titleSmallFontSize,
  //     letterSpacing: typeTokens.titleSmallLetterSpacing,
  //   },
  //   title_medium: {
  //     fontWeight: typeTokens.titleMediumFontWeight,
  //     lineHeight: typeTokens.titleMediumLineHeight,
  //     fontSize: typeTokens.titleMediumFontSize,
  //     letterSpacing: typeTokens.titleMediumLetterSpacing,
  //   },
  //   title_large: {
  //     fontWeight: typeTokens.titleLargeFontWeight,
  //     lineHeight: typeTokens.titleLargeLineHeight,
  //     fontSize: typeTokens.titleLargeFontSize,
  //     letterSpacing: typeTokens.titleLargeLetterSpacing,
  //   },
  // },
});

export default Themes;