import { Box } from "@mui/material";

const WrapLayout = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "70rem",
          padding: { xs: "0 1rem", sm: "0 3rem" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default WrapLayout;
