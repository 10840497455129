import moment from "moment";
import { useState } from "react";
import { Edit, ExpandLess, ExpandMore } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";

const CashierCard = ({ cashier }) => {
  const { role } = useAuth();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const editHandler = () => {
    navigate(`/cashier/edit/${cashier.id}`);
  };

  return (
    <Card sx={{ mb: { xs: "1.2rem", sm: "1.7rem" } }}>
      <CardHeader
        avatar={
          <Avatar
            alt={cashier.firstName}
            src={cashier.avatarUrl}
            sx={{ width: 56, height: 56 }}
          />
        }
        title={
          <Typography variant="h5">{cashier.nickName.toUpperCase()}</Typography>
        }
        subheader={moment(cashier.createdAt).format("LL")}
      />
      <CardContent>
        <Typography variant="h5">
          {`${cashier.title.toUpperCase()} ${cashier.firstName.toUpperCase()} ${cashier.lastName.toUpperCase()}`}
        </Typography>

        <Typography variant="h6">{`${cashier.phone}`}</Typography>

        <Typography variant="h6">
          {`${cashier.address.street}. ${cashier.address.city}.`}
        </Typography>

        <Typography variant="h6">
          {`${cashier.address.localGov}, ${cashier.address.state}.`}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        {role === "admin" && (
          <Button variant="outlined" endIcon={<Edit />} onClick={editHandler}>
            Edit
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={handleExpandClick}
          endIcon={expanded ? <ExpandMore /> : <ExpandLess />}
        >
          {expanded ? "Show Less" : "Show More"}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6">Gurantor Detail:</Typography>

          <CardHeader
            avatar={
              <Avatar
                alt={cashier.guarantor.firstName}
                src={cashier.guarantor.avatarUrl}
                sx={{ width: 56, height: 56 }}
              />
            }
            title={
              <Typography variant="h6" sx={{ lineHeight: "1" }}>
                {cashier.guarantor.relationship.toUpperCase()}
              </Typography>
            }
            subheader={cashier.guarantor.natureOfBusiness}
          />

          <Typography variant="h5">
            {`${cashier.guarantor.title.toUpperCase()} ${cashier.guarantor.firstName.toUpperCase()} ${cashier.guarantor.lastName.toUpperCase()}`}
          </Typography>
          <Typography variant="h6">{`${cashier.guarantor.phone}`}</Typography>

          <Typography variant="h6">
            {`${cashier.guarantor.address.street}. ${cashier.guarantor.address.city}.`}
          </Typography>

          <Typography variant="h6">
            {`${cashier.guarantor.address.localGov}, ${cashier.guarantor.address.state}.`}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CashierCard;
