import AccountInfo from "../AccountInfo";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Add, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Link,
} from "@mui/material";
import WrapLayout from "../WrapLayout";
const Accounts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dayQuery, setDayQuery] = useState("today");

  // const [cashiers, setCashiers] = useState([]);

  const handleDayChange = (event, newDayQuery) => {
    if (newDayQuery === null) {
      setDayQuery("today");
    } else {
      setDayQuery(newDayQuery);
    }
  };

  const newAccountHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    navigate("/account/create");
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "1rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Accounts</Typography>
          </Breadcrumbs>
          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={newAccountHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <Add />
              )
            }
          >
            New Account
          </Button>
        </Box>
        <Box sx={{ width: "100%", marginBottom: "2rem" }}>
          <ToggleButtonGroup
            color="primary"
            value={dayQuery}
            exclusive
            onChange={handleDayChange}
            aria-label="Platform"
          >
            <ToggleButton sx={{ lineHeight: "1" }} value="dayBefore">
              Day Before
            </ToggleButton>
            <ToggleButton sx={{ lineHeight: "1" }} value="yesterday">
              Yesterday
            </ToggleButton>
            <ToggleButton sx={{ lineHeight: "1" }} value="today">
              Today
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ width: "100%" }}>
          <AccountInfo date={dayQuery} />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Accounts;
