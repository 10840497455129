import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Done } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import NumberFormat from "./NumberFormat";
import {
  useAddNewAccAccountMutation,
  useGetAccAccountsQuery,
} from "../store/accountantAccount/accAccountApiSlice";

const PerformanceInfo = ({ date }) => {
  const thousand = new Intl.NumberFormat("en-US");
  const navigate = useNavigate();
  const { id } = useAuth();

  const initialShort = {
    amount: "",
    head: "",
    description: "",
  };
  const initialExpense = {
    amount: "",
    head: "",
    description: "",
  };

  const [short, setShort] = useState(initialShort);
  const [toggleShort, setToggleShort] = useState(false);
  const [expense, setExpense] = useState(initialExpense);
  const [submitted, setSubmitted] = useState(false);
  const [toggleExpense, setToggleExpense] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const toggleShortHandler = () => {
    setToggleShort(!toggleShort);
  };

  const toggleExpenseHandler = () => {
    setToggleExpense(!toggleExpense);
  };

  const shortChangeHandler = (event) => {
    setShort((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const expenseChangeHandler = (event) => {
    setExpense((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const {
    data: accAccount,
    isLoading,
    isSuccess,
    isError,
  } = useGetAccAccountsQuery(id);

  const [addNewAccAccount, { data: newAcc, isSuccess: updateSuccess }] =
    useAddNewAccAccountMutation();

  useEffect(() => {
    if (updateSuccess) {
      navigate(`/performance`);
    }
  }, [updateSuccess, newAcc, navigate]);

  const account = accAccount?.entities[date];
  const shortage = account?.shortage;
  const expenses = account?.expenses;

  const SubmitHandler = async (event) => {
    setSubmitLoading(true);
    event.preventDefault();
    await addNewAccAccount({
      short,
      expense,
      id: id,
    });
  };

  useEffect(() => {
    if (shortage || expenses) {
      setSubmitted(true);
    }
  }, [shortage, expenses]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ m: "2rem 0" }}>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Accounts </Typography>
      </Box>
    );
  }

  if (isSuccess) {
    return (
      <Card sx={{ mb: "1.2rem", width: "100%" }}>
        <CardContent>
          <Divider>
            <Typography variant="body2" color="primary">
              {date}
            </Typography>
          </Divider>
          <Typography variant="h5" sx={{ mt: "0.5rem", fontWeight: "bold" }}>
            CASH IN: {thousand.format(account.cashIn)}
          </Typography>
          <Typography variant="h5" color="error" sx={{ fontWeight: "bold" }}>
            Shortage: {thousand.format(account.short)}
          </Typography>

          {expenses && (
            <Typography
              variant="h5"
              color="success"
              sx={{ fontWeight: "bold" }}
            >
              Expenses: {expenses.amount ? expenses.amount : 0}
            </Typography>
          )}
          {!expenses && date !== "today" && (
            <Typography
              variant="h5"
              color="success"
              sx={{ fontWeight: "bold" }}
            >
              Expenses: 0
            </Typography>
          )}

          {shortage && (
            <Typography variant="h5" color="error" sx={{ fontWeight: "bold" }}>
              My Shortage: {shortage.amount ? shortage.amount : 0}
            </Typography>
          )}

          {!shortage && date !== "today" && (
            <Typography variant="h5" color="error" sx={{ fontWeight: "bold" }}>
              My Shortage: 0
            </Typography>
          )}

          {!toggleExpense && !submitted && date === "today" && (
            <Button
              variant="contained"
              endIcon={<Add />}
              onClick={toggleExpenseHandler}
              color="success"
              sx={{ m: "1rem 0.5rem", display: "flex" }}
            >
              Add Expenses
            </Button>
          )}

          {toggleExpense && !submitted && date === "today" && (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ color: (theme) => theme.palette.success.main }}
                >
                  EXPENSES :
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={toggleExpenseHandler}
                  color="success"
                >
                  hide
                </Button>
              </Box>
              <Box
                component="form"
                sx={{ margin: "0.5rem 0 0 1rem" }}
                noValidate
              >
                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    label="Amount"
                    value={expense.amount}
                    name="amount"
                    onChange={expenseChangeHandler}
                    InputProps={{
                      inputComponent: NumberFormat,
                    }}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    value={expense.head}
                    variant="outlined"
                    size="small"
                    name="head"
                    label="Expenses Head"
                    onChange={expenseChangeHandler}
                    fullWidth
                  />
                </Box>

                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    value={expense.description}
                    variant="outlined"
                    size="small"
                    name="description"
                    fullWidth
                    label="Description"
                    multiline
                    rows={3}
                    onChange={expenseChangeHandler}
                  />
                </Box>
              </Box>
            </>
          )}

          {!toggleShort && !submitted && date === "today" && (
            <Button
              variant="contained"
              endIcon={<Add />}
              onClick={toggleShortHandler}
              color="error"
              sx={{ m: "0 0.5rem", display: "flex" }}
            >
              Add Shortage
            </Button>
          )}

          {toggleShort && !submitted && date === "today" && (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ color: (theme) => theme.palette.error.main }}
                >
                  SHORTAGE:
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={toggleShortHandler}
                  color="error"
                >
                  hide
                </Button>
              </Box>
              <Box
                component="form"
                sx={{ margin: "0.5rem 0 0 1rem" }}
                noValidate
              >
                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    value={short.amount}
                    variant="outlined"
                    name="amount"
                    size="small"
                    label="Amount"
                    onChange={shortChangeHandler}
                    InputProps={{
                      inputComponent: NumberFormat,
                    }}
                    fullWidth
                  />
                </Box>

                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    value={short.head}
                    variant="outlined"
                    name="head"
                    size="small"
                    label="Shortage Head"
                    onChange={shortChangeHandler}
                    fullWidth
                  />
                </Box>

                <Box sx={{ mb: "0.5rem" }}>
                  <TextField
                    value={short.description}
                    variant="outlined"
                    size="small"
                    name="description"
                    fullWidth
                    label="Description"
                    multiline
                    rows={3}
                    onChange={shortChangeHandler}
                  />
                </Box>
              </Box>
            </>
          )}

          {!submitted && date === "today" && (
            <CardActions
              sx={{ justifyContent: "space-between", p: "0", mt: "2rem" }}
            >
              <Button
                variant="contained"
                type="submit"
                onClick={SubmitHandler}
                endIcon={
                  submitLoading ? (
                    <CircularProgress
                      sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                      }}
                      size={"1rem"}
                    />
                  ) : (
                    <Done />
                  )
                }
                fullWidth
              >
                Submit
              </Button>
            </CardActions>
          )}
        </CardContent>
      </Card>
    );
  }
};

export default PerformanceInfo;
