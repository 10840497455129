import TerminalInfo from "../TerminalInfo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import WrapLayout from "../WrapLayout";

const Terminals = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);

  const cashierButtonHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    navigate("/admin/terminal/new");
  };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "2rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="text.primary">Terminals</Typography>
          </Breadcrumbs>

          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={cashierButtonHandler}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  size={"1rem"}
                />
              ) : (
                <Add />
              )
            }
          >
            New {matches ? "Terminal" : "Term"}
          </Button>
        </Box>
        <Box>
          <Typography
            component="h1"
            variant={matches ? "h5" : "h6"}
            sx={{
              textAlign: "center",
              p: "0 1rem",
              m: { xs: "0 0 1.5rem", sm: "0 1rem 1.5rem" },
            }}
          >
            Deleting A Terminal Will Also Delete Every Account Associated With
            The Terminal.
          </Typography>
        </Box>

        <Box sx={{ maxWidth: "80%", width: "80%" }}>
          <TerminalInfo />
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default Terminals;
