import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const messageAdapter = createEntityAdapter({});

const initialState = messageAdapter.getInitialState();

export const messageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: () => ({
        url: "/message",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedMessages = responseData.map((message) => {
          message.id = message._id;
          return message;
        });
        return messageAdapter.setAll(initialState, loadedMessages);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Message", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Message", id })),
          ];
        } else return [{ type: "Message", id: "LIST" }];
      },
    }),
    addNewMessage: builder.mutation({
      query: (initialMessage) => ({
        url: "/message",
        method: "POST",
        body: {
          ...initialMessage,
        },
      }),
      invalidatesTags: [{ type: "Message", id: "LIST" }],
    }),
    deleteMessage: builder.mutation({
      query: (id) => ({
        url: "/message",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Message", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useAddNewMessageMutation,
  useDeleteMessageMutation,
} = messageApiSlice;

// returns the query result object
export const selectMessagesResult =
  messageApiSlice.endpoints.getMessages.select();

// creates memoized selector
const selectMessagesData = createSelector(
  selectMessagesResult,
  (MessagesResult) => MessagesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllMessages,
  selectById: selectMessageById,
  selectIds: selectMessageIds,
  // Pass in a selector that returns the Messages slice of state
} = messageAdapter.getSelectors(
  (state) => selectMessagesData(state) ?? initialState
);
