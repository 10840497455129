import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const calendarAdapter = createEntityAdapter({});

const initialState = calendarAdapter.getInitialState();

export const calendarApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCalendar: builder.query({
      query: () => ({
        url: "/calendar",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedCalendars = responseData.map((calendar) => {
          calendar.id = calendar._id;
          return calendar;
        });
        return calendarAdapter.setAll(initialState, loadedCalendars);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Calendar", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Calendar", id })),
          ];
        } else return [{ type: "Calendar", id: "LIST" }];
      },
    }),
    addNewCalendar: builder.mutation({
      query: (initialCalendar) => ({
        url: "/calendar",
        method: "POST",
        body: {
          ...initialCalendar,
        },
      }),
      invalidatesTags: [{ type: "Calendar", id: "LIST" }],
    }),
    deleteCalendar: builder.mutation({
      query: (id) => ({
        url: `/calendar`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Calendar", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCalendarQuery,
  useAddNewCalendarMutation,
  useDeleteCalendarMutation,
} = calendarApiSlice;

// returns the query result object
export const selectCalendarsResult =
  calendarApiSlice.endpoints.getCalendar.select();

// creates memoized selector
const selectCalendarsData = createSelector(
  selectCalendarsResult,
  (CalendarsResult) => CalendarsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCalendars,
  selectById: selectCalendarById,
  selectIds: selectCalendarIds,
  // Pass in a selector that returns the Calendars slice of state
} = calendarAdapter.getSelectors(
  (state) => selectCalendarsData(state) ?? initialState
);
