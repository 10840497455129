import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useEffect, useState } from "react";
import { Add, Person } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import "./CalendarCard.scss";
import useAuth from "../hooks/useAuth";
import {
  useAddNewCalendarMutation,
  useDeleteCalendarMutation,
  useGetCalendarQuery,
} from "../store/calendar/calendarApiSlice";
import { useGetCashiersQuery } from "../store/cashier/cashierApiSlice";

const CalendarCard = () => {
  const { role } = useAuth();
  const [open, setOpen] = useState(false);
  const [cashier, setCashier] = useState("");
  const [calendar, setCalendar] = useState([]);

  const [value, setValue] = useState(dayjs());

  const {
    data: calendarData,
    isLoading: calendarLoading,
    isSuccess: calendarSuccess,
    isError: calendarError,
  } = useGetCalendarQuery();

  let cashiers = [];

  const {
    data: cashiersData,
    isLoading: cashierLoading,
    isSuccess: cashierSuccess,
    isError: cashierError,
  } = useGetCashiersQuery();

  if (cashierSuccess) {
    const { entities, ids } = cashiersData;
    cashiers = ids.map((id) => entities[id]);
  }

  const [addNewCalendar, { data: newDate, isSuccess: addSuccess }] =
    useAddNewCalendarMutation();

  const [
    deleteCalendar,
    { data: deleteDate, isSuccess: deleteSuccess, isLoading: deleteLoading },
  ] = useDeleteCalendarMutation();

  const [highlightedDays, setHighlightedDays] = useState([]);

  useEffect(() => {
    if (calendarSuccess) {
      const { entities, ids } = calendarData;
      const calendarUpdate = ids.map((id) => entities[id]);
      setCalendar(calendarUpdate);
    }
  }, [calendarSuccess, calendarData]);

  useEffect(() => {
    if (addSuccess) {
      //! Add isSuccess Flash message
    }
  }, [addSuccess]);

  // useEffect(() => {
  //   if (DeleteSuccess) {
  //     //! Add Delete Success Flash message
  //     console.log("date successfully stored");
  //   }
  // }, [DeleteSuccess]);

  useEffect(() => {
    if (calendarSuccess) {
      const dateArr = calendar.map((data) => data.date);
      setHighlightedDays(dateArr);
    }
  }, [calendarSuccess, calendar, addSuccess]);

  const changeHandler = (event) => {
    setCashier(event.target.value);
  };

  // const newSalaryDate = (event) => {
  //   event.preventDefault();
  // };

  const openSalaryDialog = () => {
    setOpen(true);
  };

  const closeSalaryDialog = () => {
    setOpen(false);
  };

  const saveSalaryDate = async (date, cashier) => {
    setOpen(false);
    await addNewCalendar({
      date,
      cashier: cashier,
    });
  };

  const deleteHandler = (date) => async () => {
    await deleteCalendar(date._id);
  };

  const isLoading = (
    <Box sx={{ textAlign: "center", m: "2rem 0" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        size={"3rem"}
      />
    </Box>
  );

  const isError = (
    <div>
      <Typography variant="h5"> Something Went Wrong </Typography>
      <Typography> Unable To Fetch Accounts </Typography>
    </div>
  );

  return (
    <>
      {!calendarError && highlightedDays?.includes(value.$D) && (
        <Card sx={{ mb: "1rem", backgroundColor: "success.main" }}>
          <CardContent>
            <Divider>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                SALARY DUE DAY
              </Typography>
            </Divider>
            <Typography variant="h5" component="div" on>
              {calendar.map((data) => {
                const { date, cashier } = data;
                if (date === value.$D) {
                  const chip =
                    role === "admin" ? (
                      <Chip
                        avatar={
                          <Avatar
                            alt={cashier.nickName}
                            src={cashier.avatarUrl}
                          />
                        }
                        label={cashier.nickName}
                        onDelete={deleteHandler(data)}
                        deleteIcon={
                          deleteLoading ? (
                            <CircularProgress
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                              size={"1rem"}
                            />
                          ) : (
                            ""
                          )
                        }
                        sx={{ mr: "1rem" }}
                      />
                    ) : (
                      <Chip
                        avatar={
                          <Avatar
                            alt={cashier.nickName}
                            src={cashier.avatarUrl}
                          />
                        }
                        label={cashier.nickName}
                        sx={{ mr: "1rem" }}
                      />
                    );
                  return chip;
                } else {
                  return null;
                }
              })}
            </Typography>
          </CardContent>
        </Card>
      )}

      {calendarLoading && isLoading}

      {calendarError && isError}

      {calendarSuccess && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            orientation="portrait"
            openTo="day"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            renderDay={(day, _value, DayComponentProps) => {
              const isSelected =
                !DayComponentProps.outsideCurrentMonth &&
                highlightedDays.indexOf(day.date()) >= 0;

              return (
                <Badge
                  key={day.toString()}
                  overlap="circular"
                  badgeContent={
                    isSelected ? (
                      <Person fontSize="small" color="success" />
                    ) : undefined
                  }
                >
                  <PickersDay {...DayComponentProps} />
                </Badge>
              );
            }}
            componentsProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </LocalizationProvider>
      )}

      {role === "admin" && calendarSuccess && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            mt: "1.5rem",
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "fit-content" }}
            onClick={openSalaryDialog}
            endIcon={<Add />}
          >
            Add Date
          </Button>
          <Dialog open={open} onClose={closeSalaryDialog}>
            <DialogTitle>Add New Salary Date</DialogTitle>
            <Button variant="outlined" sx={{ m: "0 1rem 1.5rem" }} disabled>
              {`${value.$D} Of Every Month`}
            </Button>
            {cashierSuccess && (
              <FormControl sx={{ m: "0 1rem 1.5rem" }}>
                <InputLabel htmlFor="cashiers">Cashier</InputLabel>
                <Select
                  id="cashiers"
                  name="cashiers"
                  labelId="cashiers"
                  value={cashier}
                  label="Cashiers"
                  onChange={changeHandler}
                >
                  {cashiers?.map((cashier) => (
                    <MenuItem value={cashier.id} key={cashier}>
                      {cashier.nickName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <DialogActions>
              <Button onClick={closeSalaryDialog}>Cancel</Button>
              <Button
                variant="contained"
                sx={{ width: "fit-content" }}
                onClick={() => saveSalaryDate(value.$D, cashier)}
                endIcon={<Add />}
                {...(cashier ? "disabled" : "")}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default CalendarCard;
