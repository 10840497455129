import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const adminAccountAdapter = createEntityAdapter({});

const initialState = adminAccountAdapter.getInitialState();

export const adminAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminAccounts: builder.query({
      query: () => ({
        url: "/admin/account",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((account) => {
          account.id = account._id;
          return account;
        });
        return adminAccountAdapter.setAll(initialState, loadedAccounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "AdminAccount", id: "LIST" },
            ...result.ids.map((id) => ({ type: "AdminAccount", id })),
          ];
        } else return [{ type: "AdminAccount", id: "LIST" }];
      },
    }),
    updateAdminAccount: builder.mutation({
      query: (initialAccount) => ({
        url: "/admin/account",
        method: "GET",
        body: {
          ...initialAccount,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminAccount", id: arg.id },
      ],
    }),

    getLegacyAdminAccount: builder.query({
      query: (date) => ({
        url: `/admin/account/legacy?date=${date}`,
        method: "Get",
      }),
      transformResponse: (responseData) => {
        const loadedAccounts = responseData.map((account) => {
          account.id = account._id;
          return account;
        });
        return adminAccountAdapter.setAll(initialState, loadedAccounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "LegacyAdminAccount", id: "LIST" },
            ...result.ids.map((id) => ({ type: "LegacyAdminAccount", id })),
          ];
        } else return [{ type: "AdminAccount", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetAdminAccountsQuery,
  useUpdateAdminAccountMutation,
  useGetLegacyAdminAccountQuery,
} = adminAccountApiSlice;

// returns the query result object
export const selectAccountsResult =
  adminAccountApiSlice.endpoints.getAccounts.select();

// creates memoized selector
const selectAccountsData = createSelector(
  selectAccountsResult,
  (AccountsResult) => AccountsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds,
  // Pass in a selector that returns the Accounts slice of state
} = adminAccountAdapter.getSelectors(
  (state) => selectAccountsData(state) ?? initialState
);
