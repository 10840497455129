import { Delete } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useDeleteAnnouncementMutation } from "../store/announcement/announcementApiSlice";
import { useState } from "react";

const AnnouncementCard = ({ announcement }) => {
  const [loading, setLoading] = useState(false);
  const { role } = useAuth();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const deleteHandler = async () => {
    setLoading(true);
    await deleteAnnouncement(announcement.id);
  };
  return (
    <Box sx={{ maxWidth: "80" }}>
      <Card sx={{ mb: "1.2rem", width: "100%", pb: "0" }}>
        <CardContent sx={{ pb: "0" }}>
          <Typography variant="h6" component="h5">
            {announcement.head}
          </Typography>
          <Divider></Divider>
          <Typography variant="h5" component="p" mb="0.5rem">
            {announcement.body}
          </Typography>
        </CardContent>
        {role === "admin" && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "0.5rem",
            }}
          >
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ ml: "0.5rem" }}
              startIcon={
                loading ? (
                  <CircularProgress
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                    size={"1rem"}
                  />
                ) : (
                  <Delete />
                )
              }
              onClick={deleteHandler}
            >
              Delete
            </Button>
            <Typography
              variant="caption"
              component="small"
              sx={{ fontWeight: "bold" }}
            >
              {`${announcement.accountant?.firstName} ${announcement.accountant?.lastName}`}
            </Typography>
          </CardActions>
        )}
      </Card>
    </Box>
  );
};

export default AnnouncementCard;
