import { Campaign } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetAnnouncementsQuery } from "../store/announcement/announcementApiSlice";
import AnnouncementCard from "./AnnouncementCard";

const AnnouncementList = () => {
  const {
    data: announcements,
    isLoading,
    isError,
    isSuccess,
  } = useGetAnnouncementsQuery();

  const loading = (
    <Box sx={{ textAlign: "center", m: "2rem 0" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        size={"3rem"}
      />
    </Box>
  );

  const error = (
    <Box>
      <Typography variant="h5"> Something Went Wrong </Typography>
      <Typography> Unable To Fetch Announcements </Typography>
    </Box>
  );

  const empty = (
    <Box>
      <Typography variant="h5"> No Announcement At The Moment </Typography>
      <Typography variant="p" component="p">
        Keep Up The Good Work.
      </Typography>
    </Box>
  );

  let ids;
  let entities;

  if (isSuccess) {
    ({ ids, entities } = announcements);
  }

  const isEmpty = !ids?.length;

  return (
    <Box
      sx={{
        width: "100%",
        m: "1rem 0",
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Announcements</Typography>
          <Campaign sx={{ fontSize: "3rem", ml: ".5rem" }} />
        </Box>
        {isLoading && loading}
        {isError && error}
        {isEmpty && !isLoading && empty}
        {isSuccess &&
          !isEmpty &&
          ids.map((announceId) => (
            <AnnouncementCard
              key={announceId}
              announcement={entities[announceId]}
            />
          ))}
      </Box>
    </Box>
  );
};

export default AnnouncementList;
