import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const AuthenticateUser = ({ allowedRole }) => {
  const location = useLocation();
  const { role } = useAuth();

  return role ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default AuthenticateUser;
