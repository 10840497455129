import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const announcementAdapter = createEntityAdapter({});

const initialState = announcementAdapter.getInitialState();

export const announcementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: () => ({
        url: "/announcement",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAnnouncements = responseData.map((announcement) => {
          announcement.id = announcement._id;
          return announcement;
        });
        return announcementAdapter.setAll(initialState, loadedAnnouncements);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Announcement", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Announcement", id })),
          ];
        } else return [{ type: "Announcement", id: "LIST" }];
      },
    }),
    addNewAnnouncement: builder.mutation({
      query: (initialAnnouncement) => ({
        url: "/announcement",
        method: "POST",
        body: {
          ...initialAnnouncement,
        },
      }),
      invalidatesTags: [{ type: "Announcement", id: "LIST" }],
    }),
    updateAnnouncement: builder.mutation({
      query: (initialAnnouncement) => ({
        url: "/announcement",
        method: "PATCH",
        body: {
          ...initialAnnouncement,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Announcement", id: arg.id },
      ],
    }),
    deleteAnnouncement: builder.mutation({
      query: (id) => ({
        url: `/announcement`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Announcement", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useAddNewAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementApiSlice;

// returns the query result object
export const selectAnnouncementsResult =
  announcementApiSlice.endpoints.getAnnouncements.select();

// creates memoized selector
const selectAnnouncementsData = createSelector(
  selectAnnouncementsResult,
  (AnnouncementsResult) => AnnouncementsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAnnouncements,
  selectById: selectAnnouncementById,
  selectIds: selectAnnouncementIds,
  // Pass in a selector that returns the Announcements slice of state
} = announcementAdapter.getSelectors(
  (state) => selectAnnouncementsData(state) ?? initialState
);
