import {
  useGetAccountsQuery,
  useGetLegacyAccountQuery,
} from "../store/account/accountApiSlice";
import useAuth from "../hooks/useAuth";
import AccountCard from "./AccountCard";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const AccountInfo = ({ date, legacy }) => {
  const { id, role } = useAuth();
  const [accountData, setAccountData] = useState([]);
  const [isSuccess, setIsSuccess] = useState([]);

  const {
    data: account,
    isLoading: accountLoading,
    isSuccess: accountSuccess,
    isError: accountError,
  } = useGetAccountsQuery(id, { skip: Boolean(legacy) });

  const {
    data: legacyAccount,
    isLoading: legacyLoading,
    isSuccess: legacySuccess,
    isError: legacyError,
  } = useGetLegacyAccountQuery(
    { date: legacy, id },
    { skip: Boolean(!legacy) }
  );

  const isLoading = accountLoading || legacyLoading;
  const isError = accountError || legacyError;

  useEffect(() => {
    if (account) {
      setAccountData(account);
    } else if (legacyAccount) {
      setAccountData(legacyAccount);
    }
    if (accountSuccess || legacySuccess) {
      setIsSuccess(true);
    }
  }, [account, legacyAccount, accountSuccess, legacySuccess]);

  if (isError) {
    return (
      <div>
        <Typography variant="h5"> Something Went Wrong </Typography>
        <Typography> Unable To Fetch Accounts </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", m: "2rem 0" }}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          size={"3rem"}
        />
      </Box>
    );
  }

  if (isSuccess) {
    const { ids, entities } = accountData;
    const datedIds = ids?.filter((id) => entities[id].date === date);

    if (!datedIds?.length) {
      return (
        <Box>
          <Typography variant="h4"> No Account Yet </Typography>
          <Typography variant="p" component="p">
            Try adding new account
          </Typography>
        </Box>
      );
    }

    const reversedAccount = datedIds.slice().reverse();
    return reversedAccount.map((accountId) => (
      <AccountCard
        key={accountId}
        accountId={accountId}
        account={entities[accountId]}
        showAccountant={role === "admin"}
      />
    ));
  }
};

export default AccountInfo;
