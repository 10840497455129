import { useState } from "react";
import { CheckBoxRounded, EastRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import MessageForm from "../MessageForm";
const HomePage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const aboutRef = useRef();
  const contactRef = useRef();
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    navigate("/terminal");
  };

  const scrollToSetion = (elRef) => {
    window.scrollTo({
      top: elRef.current?.offsetTop,
      behavior: "smooth",
      nearest: "block",
    });
  };

  const hashHandler = () => {
    if (!hash) return;
    if (hash === "#about") {
      scrollToSetion(aboutRef);
    } else if (hash === "#contact") {
      scrollToSetion(contactRef);
    }
  };

  useEffect(() => {
    hashHandler();
  });

  return (
    <Box>
      <Box
        component="section"
        sx={{
          position: "relative",
          minHeight: "22rem",
          height: "22rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 428 415"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            style={{ position: "relative" }}
          >
            <path
              d="M430.486 -2.49989C430.486 65.3445 430.486 55.0002 430.486 161.5C430.486 281.5 430.486 359 430.486 407.183C348.11 441.237 130.436 359.257 -0.000305176 253.708C0.00147247 188.5 -0.00195312 -2.49989 -0.00195312 -2.49989C97 0.564785 89.3876 -2.5001 239.5 -2.5001C341.607 -2.5001 365.001 -4.56814 430.486 -2.49989C430.486 -48.5406 430.486 33 430.486 -2.49989Z"
              fill="#0055D5"
            />
          </svg>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            padding: { xs: "0 1rem 3rem", sm: "1rem 3rem 3rem" },
            maxWidth: "70rem",
            zIndex: "3",
          }}
        >
          <Box sx={{ width: { xs: "60%", sm: "70%" }, mt: "1rem" }}>
            <Box>
              <Typography
                variant="h6"
                component="h1"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  mb: "0.5rem",
                }}
              >
                Your Financial Solution
              </Typography>

              <Typography
                variant="body2"
                component="p"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              >
                Sampay ventures removes the hassle and waiting for you to get a
                point of sales(POS) terminal to accept card payment and much
                more. We provide you your terminal within 24hour after making
                your request.
              </Typography>
              <Button
                onClick={submitHandler}
                variant="contained"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.inverse,
                  mt: "1rem",
                }}
                endIcon={
                  loading ? (
                    <CircularProgress
                      sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                      }}
                      size={"1rem"}
                    />
                  ) : (
                    <EastRounded />
                  )
                }
              >
                Get Terminal
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "40%", sm: "30%" },
              display: "flex",
              justifyContent: "flex-end",
              height: "100%",
              mt: "0.5rem",
            }}
          >
            <Box sx={{ width: "100%", height: "100%", textAlign: "end" }}>
              <Box
                component="img"
                src="/image/pos.png"
                alt="Terminal"
                sx={{
                  width: "100%",
                  maxWidth: "11.5rem",
                  height: "100%",
                  maxHeight: "20rem",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component="section"
        sx={{
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "70rem",
            padding: { xs: "1rem", sm: "1rem 3rem 1rem" },
          }}
        >
          <Typography
            component="h4"
            variant="h4"
            sx={{ textAlign: "center", pb: "1rem" }}
          >
            WHY MONIEPOINT
          </Typography>

          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.inverse,
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <CheckBoxRounded
              sx={{
                color: (theme) => theme.palette.primary.main,
                mt: "0.2rem",
              }}
            />
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Accept Card Payment
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Accept payment from all nigeria bank cards (ATM) instantly.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.inverse,
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <CheckBoxRounded
              sx={{
                color: (theme) => theme.palette.primary.main,
                mt: "0.2rem",
              }}
            />
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Accept Transfer From Any Banks
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Accept transfers directly to your POS terminal.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.inverse,
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <CheckBoxRounded
              sx={{
                color: (theme) => theme.palette.primary.main,
                mt: "0.2rem",
              }}
            />
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Make Deposit
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Make deposit directly to any bank or mobile money operator.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.inverse,
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
          >
            <CheckBoxRounded
              sx={{
                color: (theme) => theme.palette.primary.main,
                mt: "0.2rem",
              }}
            />
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Bill Payment
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Pay electricity bills, tv subcribtion, and fund your bet
                  wallet
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component="section"
        sx={{
          backgroundColor: "#5D8BFF",
          color: (theme) => theme.palette.primary.contrastText,
          margin: "1rem auto 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // backgroundColor = ref primary60
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "70rem",
            padding: { xs: "0.5rem 1rem 1rem", sm: "1rem 3rem 1rem" },
          }}
        >
          <Typography
            component="h4"
            variant="h4"
            sx={{ textAlign: "center", pb: "1rem" }}
          >
            HOW IT WORKS
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <Avatar>1</Avatar>
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Click On Get Terminal
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Click on the ‘get terminal’ button to initiate/start the
                  process
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <Avatar>2</Avatar>
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Fill The Form
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  Fill the terminal form presented to you with the appropriate
                  information needed
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <Avatar>3</Avatar>
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Make Payment
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  After successfully submitting the form a moniepoint business
                  account will be created for you. After you activiate your
                  account you are then required to pay the terminal fee into
                  your account.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
              mb: "1rem",
            }}
          >
            <Avatar>4</Avatar>
            <Box sx={{ paddingLeft: "1rem" }}>
              <Box component="header">
                <Typography component="h4" variant="h6">
                  Get Your Terminal
                </Typography>
              </Box>
              <Box component="span">
                <Typography component="h4" variant="body1">
                  After your termial activation (step 3). Your terminal is ready
                  to be delivered.
                </Typography>

                <Typography
                  component="h4"
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                  paragraph
                >
                  Delivery in Lagos and some parts of Ogun State take less than
                  24hrs. Other locations may vary.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        component="section"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.inverse,
          margin: "0rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="about"
        ref={aboutRef}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "70rem",
            padding: { xs: "0.5rem 1rem 1rem", sm: "1rem 3rem 1rem" },
          }}
        >
          <Typography
            component="h4"
            variant="h4"
            sx={{ textAlign: "center", pb: "1rem" }}
          >
            About Us
          </Typography>
          <Box>
            <Typography component="h4" variant="body1" paragraph>
              Sampay Builds bridges between businesses and customers. We give
              businesses (such as supermarkets, minimart, filling station, bars,
              POS e.t.c ) a way to seamlessly accept payment from customers.
            </Typography>
            <Typography component="h4" variant="body1" paragraph>
              We practice what we preach. Sampay is powerfully built on customer
              experience. As the company also as multiple outlet where our
              terminals are in use, so we know what your customers need and we
              provide just that to you the bussiness owner
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        ref={contactRef}
        component="section"
        sx={{
          margin: "0rem auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "70rem",
            padding: { xs: "1rem", sm: "1rem 3rem" },
          }}
        >
          <MessageForm />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
