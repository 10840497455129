import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TerminalRequestForm from "../TerminalRequestForm";

const NewTerminalRequest = () => {
  return (
    <Box
      sx={{
        maxWidth: "90%",
        width: "90%",
        margin: "1.5rem auto",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <TerminalRequestForm />
    </Box>
  );
};

export default NewTerminalRequest;
