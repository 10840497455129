import { useGetTerminalsQuery } from "../../store/terminal/terminalApiSlice";
import { useGetCashiersQuery } from "../../store/cashier/cashierApiSlice";
import AccountForm from "../AccountForm";
import {
  Box,
  CircularProgress,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import WrapLayout from "../WrapLayout";

const NewAccount = () => {
  const {
    data: terminalObj,
    isSuccess: termSuccess,
    isLoading: termLoading,
    isError: termError,
  } = useGetTerminalsQuery();
  const {
    data: cashierObj,
    isSuccess: cashSuccess,
    isLoading: cashLoading,
    isError: cashError,
  } = useGetCashiersQuery();

  const getData = (dataObj) => {
    const Data = dataObj.ids.map((id) => dataObj?.entities[id]);
    return Data;
  };
  const terminals = termSuccess ? getData(terminalObj) : null;
  const cashiers = cashSuccess ? getData(cashierObj) : null;

  const ratings = [
    { value: "mega", key: 5 },
    { value: "super", key: 4 },
    { value: "shooting", key: 3 },
    { value: "rising", key: 2 },
    { value: "starter", key: 1 },
  ];
  const initialData = {
    cashier: "",
    terminal: "",
    withdraw: "",
    withdrawArr: [],
    transPro: "",
    transProArr: [],
    transAcc: "",
    transAccArr: [],
    posTrans: "",
    posTransArr: [],
    deposit: "",
    depositArr: [],
    airtime: "",
    airtimeArr: [],
    bill: "",
    billArr: [],
    fund: "",
    fundArr: [],
    deduct: "",
    deductArr: [],
    rating: "",
    cashOut: "",
  };

  const isError = termError || cashError;

  const errorContent = (
    <div>
      <Typography variant="h5"> Something Went Wrong </Typography>
      <Typography> Unable To Get Account Form </Typography>
    </div>
  );

  const isLoading = termLoading || cashLoading;

  const loadingContent = (
    <Box sx={{ textAlign: "center", m: "2rem 0" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
        }}
        size={"3rem"}
      />
    </Box>
  );

  const isSuccess = terminals && cashiers;

  const data = { terminals, cashiers, ratings, initialData };

  return (
    <WrapLayout>
      <Box
        sx={{
          maxWidth: "90%",
          width: "90%",
          margin: "1.5rem auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            paddingBottom: "1rem",
            marginBottom: "0.5rem",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/dashboard">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/account">
              Accounts
            </Link>
            <Typography color="text.primary">New Account</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ width: "100%" }}>
          {isError && errorContent}
          {isLoading && loadingContent}
          {isSuccess && <AccountForm data={data} />}
        </Box>
      </Box>
    </WrapLayout>
  );
};

export default NewAccount;
